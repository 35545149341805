import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step22Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step22: React.FC<Step22Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [pharmacyDetails, setPharmacyDetails] = useState<string>(
    formValues.q22_pharmacy_details?.[0] || ""
  );

  useEffect(() => {
    const isStepValid = pharmacyDetails.trim() !== "";
    setStepValid(isStepValid);

    handleChange({
      target: {
        name: "q22_pharmacy_details",
        value: [pharmacyDetails],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [pharmacyDetails, setStepValid]);

  const handlePharmacyDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPharmacyDetails(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        22. Please list the name and address of your current pharmacy.
      </p>
      <div className="mt-4">
        <textarea
          name="q22_pharmacy_details"
          value={pharmacyDetails}
          onChange={handlePharmacyDetailsChange}
          className="w-full p-2 border border-gray-300 rounded mt-2"
          rows={5}
          placeholder="Please specify if any"
        />
      </div>
    </div>
  );
};

export default Step22;
