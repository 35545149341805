import React, { useState, useEffect } from "react";
import { StepProps, CheckboxItem } from "../../../config/types";

const Step13: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const initialCheckboxes: CheckboxItem[] = [
    {
      id: 1,
      label:
        "Reduce your caloric intake alongside medication, if clinically appropriate",
      checked:
        formValues.q13_willing_to_make_changes?.includes(
          "Reduce your caloric intake alongside medication, if clinically appropriate"
        ) || false,
    },
    {
      id: 2,
      label:
        "Increase your physical activity alongside medication, if clinically appropriate",
      checked:
        formValues.q13_willing_to_make_changes?.includes(
          "Increase your physical activity alongside medication, if clinically appropriate"
        ) || false,
    },
    {
      id: 3,
      label: "None of the above",
      checked:
        formValues.q13_willing_to_make_changes?.includes("None of the above") ||
        false,
    },
  ];

  const [checkboxes, setCheckboxes] =
    useState<CheckboxItem[]>(initialCheckboxes);

  useEffect(() => {
    const isStepValid = checkboxes.some((checkbox) => checkbox.checked);
    setStepValid(isStepValid);

    const selectedOptions = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    handleChange({
      target: {
        name: "q13_willing_to_make_changes",
        value: selectedOptions,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [checkboxes, setStepValid]);

  const handleCheckboxChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.id === id,
    }));

    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        13. Are you willing to
      </p>
      <div className="my-3">
        {checkboxes.map((checkbox) => (
          <div key={checkbox.id} className="mb-3">
            <label className="flex items-center relative block">
              <img
                src={
                  checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"
                }
                width={37}
                height={37}
                alt="Checkbox Image"
              />
              <input
                type="checkbox"
                name={`checkbox-${checkbox.id}`}
                checked={checkbox.checked}
                onChange={() => handleCheckboxChange(checkbox.id)}
                className="absolute w-[37px] h-[37px] opacity-0"
              />
              <span className="ml-2 text-[#484848] text-[16px]">
                {checkbox.label}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Step13;
