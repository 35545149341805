import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step1Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step3: React.FC<Step1Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [showAdditionalOptions, setShowAdditionalOptions] = useState<string>(
    formValues.q3_what_is_your_gender?.[0] || "male"
  );

  useEffect(() => {
    const isStepValid =
      showAdditionalOptions === "male" || showAdditionalOptions === "female";
    setStepValid(isStepValid);
    updateFormValues("q3_what_is_your_gender", [showAdditionalOptions]);
  }, [showAdditionalOptions, setStepValid]);

  const handleOptionChange = (value: string) => {
    setShowAdditionalOptions(value);
    updateFormValues("q3_what_is_your_gender", [value]);
  };

  const updateFormValues = (name: string, value: string[]) => {
    handleChange({
      target: {
        name,
        value,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left">
        3. To ensure we have accurate and complete records, could you please let
        me know your gender?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("male")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            showAdditionalOptions === "male"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Male
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("female")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            showAdditionalOptions === "female"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Female
        </button>
      </div>
    </div>
  );
};

export default Step3;
