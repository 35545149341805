import React from "react";
import { useAppDispatch } from "../../../Redux/store";
import { logoutSuccess } from "../../../Redux/reducers/authSlice";
import { RootState } from "../../../Redux/rootReducer";
import { useSelector } from "react-redux";

const ProfileExecutive: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);


  const handleLogOut = () => {
    dispatch(logoutSuccess());
  };

  return (
    <div className="h-[100%]">
      <div className="h-[100%]">
        <div className="h-[100%]">
          <div className="userWrapper flex flex-col bg-[#fff] p-[20px] rounded-[20px] min-h-[300px] h-[100%]">
            <div className="userContent flex justify-between items-center">
              <div className="userProfile ">
                <img
                  src={require("../../../assets/image/smalllogo.png")}
                  alt="userprofile"
                  className="w-[150px] md:w-[200px]"
                />
              </div>
              <div className="buttonArea ">
                <button
                  type="button"
                  onClick={handleLogOut}
                  className="bg-[#3B82F6] text-[#fff] px-[20px] py-[10px] md:px-[30px] md:py-[12px] rounded-[10px] text-[18px] font-normal"
                >
                  Logout
                </button>
              </div>
            </div>
            <div className="userInfo mt-auto text-left">
              <div className="userTitle mb-[10px]">
                <h1 className="heading-one font-semibold">Welcome {user.firstName}!</h1>
              </div>
              <div className="userDescription text-[#000] text-[18px] font-normal">
                Admin
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileExecutive;
