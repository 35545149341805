import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navrouter from './executive/Routers/Rootrouter';
import { Provider } from 'react-redux';
import store from "./Redux/store";
import { persistor } from "./Redux/store";
import { PersistGate } from 'redux-persist/integration/react';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Navrouter />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
