import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { authAxios } from "../../../../config/config";
import { toast } from "react-toastify";

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: (selectedPlanId: string | null) => void;
  onCancel: () => void;
}

interface Plan {
  id: string;
  plan_name: string;
  price: number;
}

const PlansModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [listData, setListData] = useState<Plan[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      getPlansList();
    }
  }, [isOpen]);

  const getPlansList = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get("/plans");
      const resData = response?.data;
      if (resData && resData?.length > 0) {
        setListData(resData);
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response?.data?.message) {
        errMsg = error.response?.data?.message;
      } else {
        errMsg = "Failed to fetch plan list data";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanSelection = (planId: string) => {
    setSelectedPlanId(planId);
  };

  const handleConfirm = () => {
    onConfirm(selectedPlanId);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "90%",
      width: "500px",
      padding: "30px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Plans Modal"
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <h2 className="text-xl text-center font-semibold mb-4">Select Plan</h2>
      {loading ? (
        <p>Loading plans...</p>
      ) : (
        <div className="mb-4 ">
          {listData.map((plan) => (
            <div key={plan.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={plan.id}
                name="plan"
                value={plan.id}
                checked={selectedPlanId === plan.id}
                onChange={() => handlePlanSelection(plan.id)}
                className="mr-2"
              />
              <label htmlFor={plan.id} className="cursor-pointer">
                {plan.plan_name} - ${plan.price}
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="flex mt-3 mb-3 gap-3 justify-center">
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded w-full hover:bg-gray-800 transition-all duration-200"
        >
          Cancel
        </button>
        <button
          onClick={handleConfirm}
          className="bg-black text-white px-4 py-2 rounded w-full hover:bg-gray-800 transition-all duration-200"
          disabled={!selectedPlanId}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default PlansModal;
