import React from 'react';
import { useNavigate } from 'react-router-dom';

const PatientDetails: React.FC = () => {
  const navigate = useNavigate();
  
  const handleButtonClick = () => {
    navigate('/add-patient');
  };

  return (
    <div>
      <div className='flex flex-row px-[20px] lg:px-[30px] bg-[#3B82F6] items-center p-[20px] rounded-[20px] sm:min-h-[170px]  justify-between gap-x-3'>
        <div className='patientTittle text-left  text-[#fff]'>
          <h3
            className='text-[clamp(20px,4vw,40px)] leading-[1.1] cursor-pointer w-fit'
            onClick={handleButtonClick}
          >
            Enter patient details +
          </h3>
        </div>
        <div className=' text-left lg:mt-[unset] lg:text-right'>
          <button
            onClick={handleButtonClick}
            className='flex items-center text-white ml-auto'
          >
            <span className='mr-2'>
              <img
                src={require('../../assets/image/arrow-right.png')}
                alt='Arrow Right'
                className='lg:w-[50px] w-[30px]'
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
