import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step10Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step10: React.FC<Step10Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues.q10_opiate_medications?.[0] || null
  );
  const [medicationDetails, setMedicationDetails] = useState<string>(
    formValues
      .q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs?.[0] ||
      ""
  );

  useEffect(() => {
    const isStepValid =
      selectedOption !== null &&
      (selectedOption === "Yes" ? medicationDetails.trim() !== "" : true);
    setStepValid(isStepValid);

    if (selectedOption) {
      handleChange({
        target: {
          name: "q10_opiate_medications",
          value: [selectedOption],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }

    if (selectedOption === "Yes" && medicationDetails.trim() !== "") {
      handleChange({
        target: {
          name: "q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs",
          value: [medicationDetails],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [selectedOption, medicationDetails, setStepValid]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    if (value !== "Yes") {
      setMedicationDetails("");
      handleChange({
        target: {
          name: "q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs",
          value: [""],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleMedicationDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMedicationDetails(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        10. Are you currently taking, plan to take, or have recently (within the
        last 3 months) taken opiate pain medications and/or opiate-based street
        drugs? If so, please include date range, name, dose, and frequency.
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {selectedOption === "Yes" && (
        <div className="mt-4">
          <p className="text-[#000000] text-[18px] font-[400] text-left">
            Please provide us with the date range, name, dose, and frequency
          </p>
          <textarea
            name="q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs"
            value={medicationDetails}
            onChange={handleMedicationDetailsChange}
            className="w-full p-2 border border-gray-300 rounded mt-2"
            rows={5}
            placeholder="Please specify if any"
          />
        </div>
      )}
    </div>
  );
};

export default Step10;
