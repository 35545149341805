import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

const Step14: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues.q14_how_has_your_weight_changed_in_the_last_12_months?.[0] ||
      null
  );

  useEffect(() => {
    const isStepValid = selectedOption !== null;
    setStepValid(isStepValid);

    if (selectedOption) {
      handleChange({
        target: {
          name: "q14_how_has_your_weight_changed_in_the_last_12_months",
          value: [selectedOption],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [selectedOption, setStepValid]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const options = [
    "Lost a significant amount",
    "Lost a little",
    "About the same",
    "Gained a little",
    "Gained a significant amount",
  ];

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        14. How has your weight changed in the last 12 months?
      </p>
      <div className="my-3">
        {options.map((option, index) => (
          <div key={index} className="mb-3">
            <label className="flex items-center relative block">
              <img
                src={
                  selectedOption === option
                    ? "/assets/images/icons/check.svg"
                    : "/assets/images/icons/uncheck.svg"
                }
                width={37}
                height={37}
                alt="Checkbox Image"
              />
              <input
                type="checkbox"
                name={`checkbox-${index}`}
                checked={selectedOption === option}
                onChange={() => handleOptionChange(option)}
                className="absolute w-[37px] h-[37px] opacity-0"
              />
              <span className="ml-2 text-[#484848] text-[16px]">{option}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Step14;
