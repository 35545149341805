import React, { useState, useEffect } from "react";
import { CheckboxItem, StepProps } from "../../../config/types";

const Step18: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>([
    {
      id: 1,
      label: "<60 beats per minute (Slow)",
      checked:
        formValues.q18_patient_heart_rate?.includes(
          "<60 beats per minute (Slow)"
        ) || false,
    },
    {
      id: 2,
      label: "60-100 beats per minute (Normal)",
      checked:
        formValues.q18_patient_heart_rate?.includes(
          "60-100 beats per minute (Normal)"
        ) || false,
    },
    {
      id: 3,
      label: "101-110 beats per minute (Slightly Fast)",
      checked:
        formValues.q18_patient_heart_rate?.includes(
          "101-110 beats per minute (Slightly Fast)"
        ) || false,
    },
    {
      id: 4,
      label: ">110 beats per minute (Fast)",
      checked:
        formValues.q18_patient_heart_rate?.includes(
          ">110 beats per minute (Fast)"
        ) || false,
    },
  ]);

  const handleCheckboxChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.id === id,
    }));
    setCheckboxes(updatedCheckboxes);
    const selectedOptions = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    handleChange({
      target: {
        name: "q18_patient_heart_rate",
        value: selectedOptions,
      },
    } as any);

    setStepValid(selectedOptions.length > 0);
  };

  useEffect(() => {
    const selectedOptions = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    setStepValid(selectedOptions.length > 0);
  }, [checkboxes, setStepValid]);

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] mb-5">
        18. What is your current or average heart rate range?
      </p>
      <div>
        {checkboxes.map((checkbox) => (
          <label
            key={checkbox.id}
            className="flex items-center relative block mb-3"
          >
            <img
              src={checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"}
              width={37}
              height={37}
              alt="Checkbox Image"
            />
            <input
              type="checkbox"
              name={`checkbox-${checkbox.id}`}
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange(checkbox.id)}
              className="absolute w-[37px] h-[37px] opacity-0"
            />
            <span className="ml-2 text-[#484848] text-[16px]">
              {checkbox.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Step18;
