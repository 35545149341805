import React, { useState, ComponentType } from "react";
import { Circles } from "react-loader-spinner";

const Loading: React.FC = () => {
  return (
    <div className="fixed left-[0px] w-full top-[0px] min-h-screen flex items-center justify-center before:[content-['']] before:w-full before:min-h-screen before:absolute before:bg-black before:opacity-[0.5] before:z-[-1] z-[9]">
      <Circles
        height="80"
        width="80"
        color="white"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};

export interface WithLoadingProps {
  isLoading: boolean;
  setLoading: (isComponentLoading: boolean) => void;
}

const IsLoadingHOC = <P extends object>(
  WrappedComponent: ComponentType<P & WithLoadingProps>
) => {
  const HOC: React.FC<P> = (props) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const setLoadingState = (isComponentLoading: boolean) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <Loading />}
        <WrappedComponent
          {...(props as P)}
          isLoading={isLoading}
          setLoading={setLoadingState}
        />
      </>
    );
  };

  return HOC;
};

export default IsLoadingHOC;
