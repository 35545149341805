import React, { useEffect, useState } from "react";
import { StepProps } from "../../../config/types";

const Step2: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const initialHeight = formValues.q2_height?.[0] || "";
  const initialWeight = formValues.q2_weight?.[0] || "";

  const [measurements, setMeasurements] = useState({
    height: initialHeight,
    weight: initialWeight,
  });

  const [bmi, setBmi] = useState<number | null>(
    formValues.q2_bmi ? parseFloat(formValues.q2_bmi[0]) : null
  );
  const [bmiCategory, setBmiCategory] = useState<string>(
    formValues.q2_bmi_category?.[0] || ""
  );

  const calculateAndSetBmi = (measurements: {
    height: string;
    weight: string;
  }) => {
    const { height, weight } = measurements;
    if (height && weight) {
      const heightInMeters = parseFloat(height) / 100;
      const weightInKg = parseFloat(weight) * 0.453592; // Convert lbs to kg
      const calculatedBmi = weightInKg / (heightInMeters * heightInMeters);
      setBmi(calculatedBmi);

      let category = calculatedBmi.toFixed(2);
      if (calculatedBmi < 27) {
        setBmiCategory(category);
      } else if (calculatedBmi >= 27 && calculatedBmi <= 29.99) {
        setBmiCategory(category);
      } else if (calculatedBmi >= 30) {
        setBmiCategory(category);
      }
      setStepValid(true);

      updateFormValues("q2_height", [height]);
      updateFormValues("q2_weight", [weight]);
      updateFormValues("q2_bmi", [calculatedBmi.toFixed(2)]);
      updateFormValues("q2_bmi_category", [category]);
    }
  };

  useEffect(() => {
    calculateAndSetBmi(measurements);
  }, [measurements]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
  
    if (value !== '' && Number(value) < 0) {
      e.target.setCustomValidity('Negative values are not allowed');
    } else {
      e.target.setCustomValidity('');
    }
    
    if (e.target.validity.valid) {
      setMeasurements((prev) => ({
        ...prev,
        [name]: value,
      }));
      updateFormValues(`q2_${name}`, [value]);
    }
  };
  
  const updateFormValues = (name: string, value: string[]) => {
    const event = {
      target: {
        name,
        value,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    handleChange(event);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px]">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        2. What is your height and weight? (BMI Calculation)
      </p>
      <div className="flex gap-[20px] items-start my-3 mb-7 flex-wrap">
        <div className="flex flex-col items-start">
          <label className="text-[#000000] text-[16px] font-[400]">
            Height (inch)
          </label>
          <input
            type="number"
            value={measurements.height}
            onChange={handleInputChange}
            className="min-h-[45px] rounded-[5px] min-w-[200px] border border-[#3B82F6] px-2"
            name="height"
          />
        </div>
        <div className="flex flex-col items-start">
          <label className="text-[#000000] text-[16px] font-[400]">
            Weight (lbs)
          </label>
          <input
            type="number"
            value={measurements.weight}
            onChange={handleInputChange}
            className="min-h-[45px] rounded-[5px] min-w-[200px] border border-[#3B82F6] px-2"
            name="weight"
          />
        </div>
      </div>
      {bmiCategory.length > 0 && (
        <div>
          <p className="text-[#000000] text-[16px] font-[400]">
            Your BMI: {bmiCategory}
          </p>
        </div>
      )}
    </div>
  );
};

export default Step2;
