import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { trimObjValues } from "../../../../Helper";
import { authAxios } from "../../../../config/config";
import { toast } from "react-toastify";

// Define types for the form data
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  // file: File | null;
}

interface ProfilePopupProps {
  setIsPopupVisible: (isVisible: boolean) => void;
  getExecutiveList: () => void;
  userInfo: any;
}

const EditExecutiveFormModal: React.FC<ProfilePopupProps> = ({
  setIsPopupVisible,
  getExecutiveList,
  userInfo,
}) => {
  console.log("userInfo", userInfo);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: "EXECUTIVE",
  });

  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(
    null
  );

  useEffect(() => {
    if (userInfo) {
      setFormData({
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        email: userInfo.email || "",
        phoneNumber: userInfo.phoneNumber || "",
        role: userInfo.role || "EXECUTIVE",
      });
    }
  }, [userInfo]);

  // Handle change event for form inputs
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files ? files[0] : null;
      setFormData({ ...formData, [name]: file });
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const requestPayload = trimObjValues(formData);
    setLoading(true);
    try {
      const response = await authAxios().patch(
        `/executives/update/${userInfo.id}`,
        requestPayload
      );
      const resData = response.data;
      toast.success(resData?.message);
      setIsPopupVisible(false);
      getExecutiveList();
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to create executive";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#EAEBED] rounded-[10px] px-[33px] py-[38px]">
      <div>
        <h4 className="text-[25px] font-medium pb-[20px]">Edit Executive</h4>
      </div>
      <div className="userForm">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap gap-[10px] justify-center executive-wrapper">
            {/* <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px] flex items-center gap-[20px]">
              <label className="image-preview block relative w-[80px] h-[80px] cursor-pointer">
                {profileImage ? (
                  <img
                    src={profileImage as string}
                    alt="Profile"
                    className="w-[100%] h-[100%] rounded-[5px] object-cover"
                  />
                ) : (
                  <div className="placeholder w-[100%] h-[100%] flex bg-[#e0e0e0] items-center justify-center rounded-[5px]">
                  </div>
                )}
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  name="file"
                  onChange={handleChange}
                  disabled={!isEditMode}
                />
              </label>
              <span className="text-[#B0B0B0]">Replace Profile Image</span>
            </div> */}
            <div className="w-[100%] md:w-[calc(50%-5px)] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="text"
                name="firstName"
                required
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="w-[100%] md:w-[calc(50%-5px)] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="text"
                name="lastName"
                required
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="email"
                name="email"
                required
                disabled
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="text"
                name="phoneNumber"
                required
                max={10}
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            {/* <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="password"
                name="password"
                required
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div> */}
            <button
              type="submit"
              disabled={loading}
              className="bg-[#3B82F6] text-[#fff] px-[30px] py-[12px] rounded-[8px] text-[18px] font-normal mt-[1rem]"
            >
              {loading ? "Processing" : "Update Executive"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditExecutiveFormModal;
