import React from 'react';
import { useNavigate } from 'react-router-dom';

const CheckoutExecutiveDetail: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/customers-executives');
    };

    return (
        <div onClick={handleNavigate} className='cursor-pointer'>
            <div className='flex flex-col justify-between px-[20px] md:px-[30px] bg-[#fff] items-center p-[20px] rounded-[20px] min-h-[300px] lg:px-[30px]'>
                <div className='patientTittle w-[100%] text-left text-[#000]'>
                    <span className='text-[#484848] text-[18px]'>Check out all Customer Executive Details</span>
                </div>
                <div className='w-[100%] mt-auto md:mt-[unset] flex justify-between'>
                    <div>
                        <span className='text-[#000] font-medium text-[25px]'>All Customer Executive</span>
                    </div>
                    <div>
                        <button className='flex items-center'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 1V17M1 9H17" stroke="#2F2F38" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutExecutiveDetail;
