import React, { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { withoutAuthAxios } from '../../../config/config';
import hideEyeIcon from '../../../assets/image/hideeye.png';
import showEyeIcon from '../../../assets/image/showeye.png';

const ConfirmPasswordPage: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('resetEmail');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    if (password !== repeatPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!code || !email) {
      setError('Invalid or missing code/email.');
      return;
    }

    setLoading(true);

    try {
      const response = await withoutAuthAxios().post('/executives/confirm-password', {
        email,
        code,
        password,
        repeatPassword,
      });

      if (response.data) {
        toast.success('Password updated successfully!');
        localStorage.removeItem('resetEmail');
        navigate('/login');
      }
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
        toast.error('An unexpected error occurred. Please try again.');
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const handleLogoClick = () => {
    navigate('/login');
  };
  return (
    <section className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8">
      <img
          src={require("../../../assets/image/SiteLogo.png")}
          alt="Logo"
          className="mx-auto mb-6 cursor-pointer" 
          style={{ width: '150px' }}
          onClick={handleLogoClick} 
        />
        <div className="text-center text-2xl font-semibold mb-6">
          Reset Your Password
        </div>
        {error && (
          <div className="text-red-600 bg-red-100 border border-red-600 p-4 mb-4 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-2">Verification Code</label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              placeholder="Enter verification code"
              className="w-full bg-gray-50 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">New Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter new password"
              className="w-full bg-gray-50 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              <img
                src={showPassword ? hideEyeIcon : showEyeIcon}
                alt="toggle visibility"
                className='mt-6'
                width={15}
                height={18}
              />
            </button>
          </div>
          <div className="mb-6 relative">
            <label htmlFor="repeat-password" className="block text-sm font-medium text-gray-700 mb-2">Confirm Password</label>
            <input
              type={showRepeatPassword ? 'text' : 'password'}
              id="repeat-password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
              placeholder="Confirm new password"
              className="w-full bg-gray-50 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={() => setShowRepeatPassword(!showRepeatPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              <img
                src={showRepeatPassword ? hideEyeIcon : showEyeIcon}
                alt="toggle visibility"
                className='mt-6'
                width={15}
                height={18}
              />
            </button>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Confirm Password'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ConfirmPasswordPage;
