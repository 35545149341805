import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step20Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step20: React.FC<Step20Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [additionalInfo, setAdditionalInfo] = useState<string>(
    formValues.q20_additional_info?.[0] || "No"
  );

  const selectedOption = additionalInfo !== "No" ? "Yes" : "No";

  useEffect(() => {
    const isStepValid = selectedOption === "No" || additionalInfo.trim() !== "";
    setStepValid(isStepValid);

    handleChange({
      target: {
        name: "q20_further_information",
        value: [selectedOption],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);

    handleChange({
      target: {
        name: "q20_additional_info",
        value: [additionalInfo],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [selectedOption, additionalInfo, setStepValid]);

  const handleOptionChange = (value: string) => {
    if (value === "No") {
      setAdditionalInfo("No");
    } else if (value === "Yes" && additionalInfo === "No") {
      setAdditionalInfo("");
    }
  };

  const handleAdditionalInfoChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAdditionalInfo(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        20. Do you have any further information which you would like the
        provider to know? Please do not include urgent or emergent medical
        information here, as this is not reviewed immediately.
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {selectedOption === "Yes" && (
        <div className="mt-4">
          <p className="text-[#000000] text-[18px] font-[400] text-left">
            What information would you like to share with the provider?
          </p>
          <textarea
            name="q20_additional_info"
            value={additionalInfo === "No" ? "" : additionalInfo}
            onChange={handleAdditionalInfoChange}
            className="w-full p-2 border border-gray-300 rounded mt-2"
            rows={5}
            placeholder="Please provide additional info"
          />
        </div>
      )}
    </div>
  );
};

export default Step20;
