import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step16Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step16: React.FC<Step16Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues.q16_diagnosed_with_prediabetes_or_type_2_diabetes?.[0] || null
  );
  const [fastingGlucose, setFastingGlucose] = useState<string>(
    formValues.q16_fasting_glucose_value_part_a?.[0] || ""
  );
  const [hemoglobinA1c, setHemoglobinA1c] = useState<string>(
    formValues.q16_hemoglobin_a1c_value_part_b?.[0] || ""
  );

  useEffect(() => {
    const isStepValid =
      selectedOption !== null &&
      (selectedOption === "No" ||
        (selectedOption === "Yes" &&
          fastingGlucose.trim() !== "" &&
          hemoglobinA1c.trim() !== ""));
    setStepValid(isStepValid);
  }, [selectedOption, fastingGlucose, hemoglobinA1c, setStepValid]);

  useEffect(() => {
    if (selectedOption !== null) {
      handleChange({
        target: {
          name: "q16_diagnosed_with_prediabetes_or_type_2_diabetes",
          value: [selectedOption],
        },
      } as any);
    }
  }, [selectedOption]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleFastingGlucoseChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setFastingGlucose(value);
    handleChange({
      target: {
        name: "q16_fasting_glucose_value_part_a",
        value: [value],
      },
    } as any);
  };

  const handleHemoglobinA1cChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setHemoglobinA1c(value);
    handleChange({
      target: {
        name: "q16_hemoglobin_a1c_value_part_b",
        value: [value],
      },
    } as any);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        16. Have you been diagnosed with prediabetes or type 2 diabetes?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {selectedOption === "Yes" && (
        <>
          <div className="mt-4">
            <p className="text-[#000000] text-[18px] font-[400] text-left">
              What was your most recent fasting glucose value?
            </p>
            <input
              type="text"
              name="q16_fasting_glucose_value_part_a"
              value={fastingGlucose}
              onChange={handleFastingGlucoseChange}
              className="w-full p-2 border border-gray-300 rounded mt-2"
            />
          </div>
          <div className="mt-4">
            <p className="text-[#000000] text-[18px] font-[400] text-left">
              What was your most recent hemoglobin A1c (HbA1c) value?
            </p>
            <input
              type="text"
              name="q16_hemoglobin_a1c_value_part_b"
              value={hemoglobinA1c}
              onChange={handleHemoglobinA1cChange}
              className="w-full p-2 border border-gray-300 rounded mt-2"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Step16;
