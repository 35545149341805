import React from 'react';
import { NavLink } from 'react-router-dom';

// Define the Logo component
const Logo: React.FC = () => {
  return (
    <NavLink to="/">
      <img
        src='../../../assets/images/SiteLogo.png'
        alt="Site Logo"
        className="m-auto"
      />
    </NavLink>
  );
};

export default Logo;
