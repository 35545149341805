import React, { useEffect, useState } from "react";
import EditExecutiveFormModal from "../common/forms/EditExecutiveFormModal";
import ConfirmDeletePopup from "../common/Popup/ConfirmDeletePopup";
import ProfilePopup from "../profile/ProfilePopup";
import { toast } from "react-toastify";
import { authAxios } from "../../../config/config";

const ExecutiveList: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [listData, setListData] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeletePopupVisible, setIsDeletePopupVisible] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  useEffect(() => {
    getExecutiveList();
  }, []);

  const getExecutiveList = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get("/executives/list");
      const resData = response?.data?.executives;
      if (resData && resData?.length > 0) {
        setListData(resData);
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response?.data?.message) {
        errMsg = error.response?.data?.message;
      } else {
        errMsg = "Failed to fetch excutive list data";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = (item: any) => {
    setUserInfo(item);
    setIsPopupVisible(true);
  };

  const handleDeleteClick = (user: any) => {
    setUserId(user.id);
    setIsDeletePopupVisible(true);
  };

  const handleCloseDeletePopup = () => {
    setIsDeletePopupVisible(false);
    setUserId("");
  };

  const handleConfirmDelete = async () => {
    if (userId) {
      setLoading(true);
      try {
        const response = await authAxios().delete(`/executives/${userId}`);
        const resData = response?.data;
        console.log("resData", resData)
        toast.success(resData.message);
        getExecutiveList();
      } catch (error: any) {
        let errMsg = "";
        if (Array.isArray(error.response?.data?.message)) {
          errMsg = error.response?.data?.message[0];
        } else if (error.response?.data?.message) {
          errMsg = error.response?.data?.message;
        } else {
          errMsg = "Failed to fetch excutive list data";
        }
        toast.error(errMsg);
      } finally {
        setLoading(false);
      }
    }
    setIsDeletePopupVisible(false);
  };

  return (
    <>
      <div className="w-[100%] overflow-x-auto">
        <table className="w-[100%] custom--edittable">
          <thead className="text-[#9A9A9A] font-normal text-[14px] text-left">
            <tr className="border-b border-gray-300">
              <th>Name</th>
              <th>Email</th>
              <th>Phone number</th>
              <th className="text-center min-w-[200px]">
                Total Leads
              </th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody className="text-left font-normal text-[14px]">
            {listData?.map((item: any, index) => (
              <tr key={index}>
                <td className="min-w-[150px]">
                  {item.firstName + " " + item.lastName}
                </td>
                <td>{item.email}</td>
                <td>{item.phoneNumber}</td>
                <td className="text-center">{item.leadCount}</td>
                <td className="min-w-[300px]">
                  <div className="flex justify-center gap-[10px]">
                    <div>
                      <button
                        className="delete--button bg-[#FFE0E0] text-[#FF0101] px-[20px] py-[12px] rounded-[100px] text-[14px] font-normal"
                        onClick={() => handleDeleteClick(item)}
                      >
                        Delete User
                      </button>
                    </div>
                    <div>
                      <button
                        className="edit--button bg-[#EAEBED] text-[#000] px-[20px] py-[12px] rounded-[100px] text-[14px] font-normal"
                        onClick={() => handleEditUser(item)}
                      >
                        Edit Details
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ProfilePopup
        isOpen={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
      >
        <div className="formWrapper justify-center flex h-[500px] flex-col max-w-[1300px] py-[20px] m-auto md:py-[50px] gap-[20px] md:flex-row md:h-[unset] overflow-y-auto">
          <div className="w-[100%] md:w-[50%]">
            <EditExecutiveFormModal
              setIsPopupVisible={setIsPopupVisible}
              userInfo={userInfo}
              getExecutiveList={getExecutiveList}
            />
          </div>
        </div>
      </ProfilePopup>
      <ConfirmDeletePopup
        isVisible={isDeletePopupVisible}
        onClose={handleCloseDeletePopup}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default ExecutiveList;
