import React, { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { State } from "country-state-city";
import { StepProps } from "../../../config/types";

interface Step7Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Option {
  label: string;
  value: string;
  isoCode: string;
}

const Step7: React.FC<Step7Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues.q7_will_you_be_located_here_at_the_time_of_your_visit?.[0] ||
      null
  );
  const [stateInput, setStateInput] = useState<string>(
    formValues
      .q7_a_state_you_will_be_located_in_at_the_time_of_your_visit?.[0] || ""
  );
  const [stateOptions, setStateOptions] = useState<Option[]>([]);
  const [defaultState, setDefaultState] = useState<SingleValue<Option>>(null);

  useEffect(() => {
    const states = State.getStatesOfCountry("US");
    const options = states.map((state) => ({
      label: state.name,
      value: `${state.name}-${state.isoCode}`,
      isoCode: state.isoCode,
    }));
    setStateOptions(options);
  }, []);

  useEffect(() => {
    const isStepValid = selectedOption !== null && stateInput.trim() !== "";
    setStepValid(isStepValid);

    if (selectedOption) {
      handleChange({
        target: {
          name: "q7_will_you_be_located_here_at_the_time_of_your_visit",
          value: [selectedOption],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }

    if (stateInput) {
      handleChange({
        target: {
          name: "q7_a_state_you_will_be_located_in_at_the_time_of_your_visit",
          value: [stateInput],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [selectedOption, stateInput, setStepValid]);

  const handleOptionChange = (value: string) => {
    if (value !== selectedOption) {
      setSelectedOption(value);
      setStateInput("");
      setDefaultState(null); // Reset the select box value

      handleChange({
        target: {
          name: "q7_will_you_be_located_here_at_the_time_of_your_visit",
          value: [value],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);

      // Clear previous state detail if option is changed
      if (value === "No") {
        handleChange({
          target: {
            name: "q7_a_state_you_will_be_located_in_at_the_time_of_your_visit",
            value: [""],
          },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  const handleStateChange = (selectedOption: SingleValue<Option>) => {
    if (selectedOption) {
      setStateInput(selectedOption.value);
      setDefaultState(selectedOption); // Set the default value for the select box
      handleChange({
        target: {
          name: "q7_a_state_you_will_be_located_in_at_the_time_of_your_visit",
          value: [selectedOption.value],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    } else {
      setStateInput("");
    }
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left">
        7. Will you be located here at the time of your visit?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      <div className="mt-4">
        {selectedOption === "No" && (
          <p className="text-[#000000] text-[18px] font-[400] text-left mt-4">
            7a. What state will you be located in at the time of your visit?
          </p>
        )}
        <Select
          options={stateOptions}
          value={
            defaultState ||
            stateOptions.find((option) => option.value === stateInput)
          }
          onChange={handleStateChange}
          className="w-full max-w-[200px] mt-2 custom-dropdown-border"
        />
      </div>
    </div>
  );
};

export default Step7;
