import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode, JwtPayload } from 'jwt-decode'; 
import { logoutSuccess } from '../../../Redux/reducers/authSlice';
import { RootState } from '../../../Redux/rootReducer';

interface DecodedToken extends JwtPayload {
  client_id?: string;
  origin_jti?: string;
  event_id?: string;
  token_use?: string;
  scope?: string;
  auth_time?: number;
  username?: string;
}

const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const ComponentWithAuth: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accessToken } = useSelector((state: RootState) => state.auth);
    useEffect(() => {
      if (accessToken) {
        const decodedToken: DecodedToken = jwtDecode<DecodedToken>(accessToken);
        if (decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
          dispatch(logoutSuccess()); 
          navigate('/login'); 
        }
      } else {
        dispatch(logoutSuccess());
        navigate('/login');
      }
    }, [dispatch, navigate]);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
