import React, { useState, useEffect } from "react";
import { StepProps, CheckboxItem } from "../../../config/types";

const Step4: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(
    formValues.q1_option_change || "no"
  );

  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>([
    {
      id: 1,
      label:
        "Currently or possibly pregnant, or actively trying to become pregnant",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Currently or possibly pregnant, or actively trying to become pregnant"
        ) || false,
    },
    {
      id: 2,
      label: "Breastfeeding or bottle-feeding with breastmilk",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Breastfeeding or bottle-feeding with breastmilk"
        ) || false,
    },
    {
      id: 3,
      label: "End-Stage kidney disease (or about to be on dialysis)",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "End-Stage kidney disease (or about to be on dialysis)"
        ) || false,
    },
    {
      id: 4,
      label: "End-stage liver disease (cirrhosis)",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "End-stage liver disease (cirrhosis)"
        ) || false,
    },
    {
      id: 5,
      label: "Current or prior eating disorder (anorexia/bulimia)",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Current or prior eating disorder (anorexia/bulimia)"
        ) || false,
    },
    {
      id: 6,
      label: "Current suicidal thoughts and/or prior suicidal attempt",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Current suicidal thoughts and/or prior suicidal attempt"
        ) || false,
    },
    {
      id: 7,
      label:
        "Cancer (active diagnosis, active treatment, or in remission or cancer-free for less than 5 continuous years)",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Cancer (active diagnosis, active treatment, or in remission or cancer-free for less than 5 continuous years)"
        ) || false,
    },
    {
      id: 8,
      label: "History of organ transplant on anti-rejection medication",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "History of organ transplant on anti-rejection medication"
        ) || false,
    },
    {
      id: 9,
      label:
        "Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)"
        ) || false,
    },
    {
      id: 10,
      label: "None of the above",
      checked:
        formValues.q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us?.includes(
          "None of the above"
        ) || false,
    },
  ]);

  const handleOptionChange = (value: string) => {
    setShowAdditionalOptions(value);
    handleChange({ target: { name: "q1_option_change", value } } as any);
    if (value === "no") {
      handleChange({
        target: {
          name: "q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us",
          value: ["no"],
        },
      } as any);
    }
    setStepValid(validateStep(value, checkboxes));
  };

  const handleCheckboxChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );

    // Uncheck 'None of the above' if any other option is selected
    if (id !== 10 && updatedCheckboxes[id - 1].checked) {
      updatedCheckboxes[9].checked = false;
    }

    // Uncheck all other options if 'None of the above' is selected
    if (id === 10 && updatedCheckboxes[9].checked) {
      updatedCheckboxes.forEach((checkbox, index) => {
        if (index !== 9) checkbox.checked = false;
      });
    }

    setCheckboxes(updatedCheckboxes);
    const selectedOptions = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);
    handleChange({
      target: {
        name: "q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us",
        value: selectedOptions,
      },
    } as any);
    setStepValid(validateStep(showAdditionalOptions, updatedCheckboxes));
  };

  useEffect(() => {
    if (showAdditionalOptions === "no") {
      handleChange({
        target: {
          name: "q4_have_there_been_any_changes_to_your_medical_social_or_surgical_history_since_your_last_visit_with_us",
          value: ["No"],
        },
      } as any);
    }
    setStepValid(validateStep(showAdditionalOptions, checkboxes));
  }, [showAdditionalOptions, checkboxes, setStepValid]);

  const validateStep = (optionChange: string, checkboxes: CheckboxItem[]) => {
    if (optionChange === "no") {
      return true;
    }
    const isAnyCheckboxChecked = checkboxes.some(
      (checkbox) => checkbox.checked
    );
    return isAnyCheckboxChecked;
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400]">
        4. Have there been any changes to your medical, social, or surgical
        history since your last visit with us?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            showAdditionalOptions === "yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("no")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            showAdditionalOptions === "no"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {showAdditionalOptions === "yes" && (
        <div>
          <p className="text-[16px] text-[#000] mb-5">
            Do any of the following apply to you?
          </p>
          {checkboxes.map((checkbox) => (
            <div key={checkbox.id} className="mb-3">
              <label className="flex items-center relative block">
                <img
                  src={
                    checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"
                  }
                  width={37}
                  height={37}
                  alt="Checkbox Image"
                />
                <input
                  type="checkbox"
                  name={`checkbox-${checkbox.id}`}
                  checked={checkbox.checked}
                  onChange={() => handleCheckboxChange(checkbox.id)}
                  className="absolute w-[37px] h-[37px] opacity-0"
                />
                <span className="ml-2 text-[#484848] text-[16px]">
                  {checkbox.label}
                </span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Step4;
