import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step1Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step5: React.FC<Step1Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues
      .q5_are_you_currently_taking_or_have_recently_within_the_last_12_months_taken_medication_for_weight_loss?.[0] ||
      null
  );

  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      label:
        "Yes, I currently take or have recently (within the last 12 months) taken a GLP-1 medication for weight loss",
      checked:
        formValues.q5_are_you_currently_taking_or_have_recently_within_the_last_12_months_taken_medication_for_weight_loss?.includes(
          "Yes, I currently take or have recently (within the last 12 months) taken a GLP-1 medication for weight loss"
        ) || false,
    },
    {
      id: 2,
      label:
        "Yes, I currently take or have recently (within the last 12 months) taken another medication for weight loss",
      checked:
        formValues.q5_are_you_currently_taking_or_have_recently_within_the_last_12_months_taken_medication_for_weight_loss?.includes(
          "Yes, I currently take or have recently (within the last 12 months) taken another medication for weight loss"
        ) || false,
    },
    {
      id: 3,
      label: "No",
      checked:
        formValues.q5_are_you_currently_taking_or_have_recently_within_the_last_12_months_taken_medication_for_weight_loss?.includes(
          "No"
        ) || false,
    },
  ]);

  useEffect(() => {
    const isStepValid = checkboxes.some((checkbox) => checkbox.checked);
    setStepValid(isStepValid);
  }, [checkboxes, setStepValid]);

  const handleOptionChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map(
      (checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : { ...checkbox, checked: false } // Uncheck other checkboxes
    );
    setCheckboxes(updatedCheckboxes);

    const selectedOptions = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);
    setSelectedOption(selectedOptions[0] || null);
    handleChange({
      target: {
        name: "q5_are_you_currently_taking_or_have_recently_within_the_last_12_months_taken_medication_for_weight_loss",
        value: selectedOptions,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSubQuestionChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;

    if (type === 'number') {
      if (value !== '' && Number(value) < 0) {
        e.target.setCustomValidity('Negative values are not allowed');
      } else {
        e.target.setCustomValidity('');
      }
      if (!e.target.validity.valid) {
        return;
      }
    }

    handleChange({
      target: {
        name: e.target.name,
        value: [e.target.value],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileNames = Array.from(e.target.files).map((file) => file.name);
      handleChange({
        target: {
          name: e.target.name,
          value: fileNames,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-gray-800 text-lg font-medium mb-4">
        5. Are you currently taking or have recently (within the last 12 months)
        taken medication(s) for weight loss?
      </p>
      <div className="my-3 space-y-3">
        {checkboxes.map((checkbox) => (
          <div key={checkbox.id} className="flex items-center space-x-2">
            <label
              htmlFor={`checkbox-${checkbox.id}`}
              className="flex items-center text-gray-700"
            >
              <img
                src={
                  checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"
                }
                width={37}
                height={37}
                alt="Checkbox Image"
              />
              <input
                type="checkbox"
                name={`checkbox-${checkbox.id}`}
                checked={checkbox.checked}
                onChange={() => handleOptionChange(checkbox.id)}
                className="absolute w-[37px] h-[37px] opacity-0"
              />
              <span className="ml-2 text-[#484848] text-[16px]">
                {checkbox.label}
              </span>
            </label>
          </div>
        ))}
      </div>

      {selectedOption?.includes("GLP-1 medication") && (
        <>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5a. Please list the name, dose, and frequency of your current or
              recent (within the last 12 months) weight loss medication(s).
            </p>
            <input
              type="text"
              name="q5_please_list_the_name_dose_and_frequency_of_your_current_or_recent_within_the_last_12_months_weight_loss_medications_part_a"
              value={
                formValues
                  .q5_please_list_the_name_dose_and_frequency_of_your_current_or_recent_within_the_last_12_months_weight_loss_medications_part_a?.[0] ||
                ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5b. What was your starting weight in pounds (lbs)?
            </p>
            <input
              type="number"
              name="q5_what_was_your_starting_weight_in_pounds_part_b"
              value={
                formValues
                  .q5_what_was_your_starting_weight_in_pounds_part_b?.[0] || ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5c. What is your current weight in pounds (lbs)?
            </p>
            <input
              type="number"
              name="q5_what_is_your_current_weight_in_pounds_part_c"
              value={
                formValues
                  .q5_what_is_your_current_weight_in_pounds_part_c?.[0] || ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5d. Do you agree to only obtain weight loss medication through
              MyStart Health moving forward?
            </p>
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d"
                  value="Yes"
                  checked={
                    formValues
                      .q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d?.[0] ===
                    "Yes"
                  }
                  onChange={handleSubQuestionChange}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">Yes</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d"
                  value="No"
                  checked={
                    formValues
                      .q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d?.[0] ===
                    "No"
                  }
                  onChange={handleSubQuestionChange}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">No</span>
              </label>
            </div>
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5e. When was your last dose of medication? This question is
              required before further medication can be prescribed.
            </p>
            <div className="flex flex-col space-y-2">
              {["0-5 days", "6-10 days", "11-14 days", "> 14 days"].map(
                (option) => (
                  <label key={option} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="q5_when_was_your_last_dose_of_medication_part_e"
                      value={option}
                      checked={
                        formValues
                          .q5_when_was_your_last_dose_of_medication_part_e?.[0] ===
                        option
                      }
                      onChange={handleSubQuestionChange}
                      className="form-radio text-blue-600"
                    />
                    <span className="text-gray-700">{option}</span>
                  </label>
                )
              )}
            </div>
          </div>
          {["0-5 days", "6-10 days", "11-14 days"].includes(
            formValues.q5_when_was_your_last_dose_of_medication_part_e?.[0]
          ) && (
            <div className="mt-6">
              <p className="text-gray-800 text-lg font-medium mb-2">
                5f. Please upload a picture of your current GLP-1 medication pen
                or vial.
              </p>
              <input
                type="file"
                name="q5_picture_of_your_current_GLP_1_medication_pen_or_vial_part_f"
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
              {formValues.q5_picture_of_your_current_GLP_1_medication_pen_or_vial_part_f &&
                formValues.q5_picture_of_your_current_GLP_1_medication_pen_or_vial_part_f.map(
                  (fileName: string, index: number) => (
                    <p key={index} className="text-gray-600 mt-2">
                      {fileName}
                    </p>
                  )
                )}
            </div>
          )}
        </>
      )}

      {selectedOption?.includes("another medication") && (
        <>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5a. Please list the name, dose, and frequency of your current or
              recent (within the last 12 months) weight loss medication(s).
            </p>
            <input
              type="text"
              name="q5_please_list_the_name_dose_and_frequency_of_your_current_or_recent_within_the_last_12_months_weight_loss_medications_part_a"
              value={
                formValues
                  .q5_please_list_the_name_dose_and_frequency_of_your_current_or_recent_within_the_last_12_months_weight_loss_medications_part_a?.[0] ||
                ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5b. What was your starting weight in pounds (lbs)?
            </p>
            <input
              type="number"
              name="q5_what_was_your_starting_weight_in_pounds_part_b"
              value={
                formValues
                  .q5_what_was_your_starting_weight_in_pounds_part_b?.[0] || ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5c. What is your current weight in pounds (lbs)?
            </p>
            <input
              type="number"
              name="q5_what_is_your_current_weight_in_pounds_part_c"
              value={
                formValues
                  .q5_what_is_your_current_weight_in_pounds_part_c?.[0] || ""
              }
              onChange={handleSubQuestionChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mt-6">
            <p className="text-gray-800 text-lg font-medium mb-2">
              5d. Do you agree to only obtain weight loss medication through
              MyStart Health moving forward?
            </p>
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d"
                  value="Yes"
                  checked={
                    formValues
                      .q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d?.[0] ===
                    "Yes"
                  }
                  onChange={handleSubQuestionChange}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">Yes</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d"
                  value="No"
                  checked={
                    formValues
                      .q5_do_you_agree_to_only_obtain_weight_loss_medication_through_MyStart_Health_moving_forward_part_d?.[0] ===
                    "No"
                  }
                  onChange={handleSubQuestionChange}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">No</span>
              </label>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step5;
