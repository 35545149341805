import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import SuperAdminDash from "../Pages/SuperAdminDash";
import AddPatientMoreDetail from "../Components/AddPatientDetails";
import Login from "../Components/login/Login";
import CustomersExecutive from "../Components/common/forms/CustomersExecutive";
import AddPatient from "../Components/AddPatient";
import PrivateRoute from "./PrivateRoute";
import { RootState } from "../../Redux/rootReducer";
import { useSelector } from "react-redux";
import ChangePassword from "../Components/changePassword/ChangePassword";
import ConfirmPasswordPage from "../Components/common/ConfirmPassword";

const Rootrouter: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/confirm-password" element={<ConfirmPasswordPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              {user?.role === "ADMIN" ? <SuperAdminDash /> : <Dashboard />}
            </PrivateRoute>
          }
        />
        <Route
          path="/add-patient"
          element={
            <PrivateRoute>
              {" "}
              <AddPatient />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/add-patient-details"
          element={
            <PrivateRoute>
              {" "}
              <AddPatientMoreDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers-executives"
          element={
            <PrivateRoute>
              {" "}
              <CustomersExecutive />{" "}
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default Rootrouter;
