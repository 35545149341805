import React, { useState, useEffect } from "react";
import { CheckboxItem, StepProps } from "../../../config/types";

const Step17: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>([
    {
      id: 1,
      label: "<120/80 (Normal)",
      checked:
        formValues.q17_patient_blood_pressure_range?.includes(
          "<120/80 (Normal)"
        ) || false,
    },
    {
      id: 2,
      label: "120-129/<80 (Elevated)",
      checked:
        formValues.q17_patient_blood_pressure_range?.includes(
          "120-129/<80 (Elevated)"
        ) || false,
    },
    {
      id: 3,
      label: "130-139/80-89 (High Stage 1)",
      checked:
        formValues.q17_patient_blood_pressure_range?.includes(
          "130-139/80-89 (High Stage 1)"
        ) || false,
    },
    {
      id: 4,
      label: "≥140/90 (High Stage 2)",
      checked:
        formValues.q17_patient_blood_pressure_range?.includes(
          "≥140/90 (High Stage 2)"
        ) || false,
    },
  ]);

  useEffect(() => {
    const validateStep = () => {
      const isAnyCheckboxChecked = checkboxes.some(
        (checkbox) => checkbox.checked
      );
      setStepValid(isAnyCheckboxChecked);
    };

    validateStep();
  }, [checkboxes, setStepValid]);

  const handleCheckboxChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: checkbox.id === id ? !checkbox.checked : false, // Ensure only one checkbox is selected at a time
    }));
    setCheckboxes(updatedCheckboxes);

    const selectedOptions = updatedCheckboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    handleChange({
      target: {
        name: "q17_patient_blood_pressure_range",
        value: selectedOptions,
      },
    } as any);

    const isAnyCheckboxChecked = updatedCheckboxes.some(
      (checkbox) => checkbox.checked
    );
    setStepValid(isAnyCheckboxChecked);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] mb-5">
        17. What is your current or average blood pressure range?
      </p>
      <div>
        {checkboxes.map((checkbox) => (
          <label
            key={checkbox.id}
            className="flex items-center relative block mb-3"
          >
            <img
              src={checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"}
              width={37}
              height={37}
              alt="Checkbox Image"
            />
            <input
              type="checkbox"
              name={`checkbox-${checkbox.id}`}
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange(checkbox.id)}
              className="absolute w-[37px] h-[37px] opacity-0"
            />
            <span className="ml-2 text-[#484848] text-[16px]">
              {checkbox.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Step17;
