import React, { useState } from "react";
import ProfileForm from "./profile/ProfileForm";
import ProfilePassword from "./profile/ProfilePassword";
import ProfilePopup from "./profile/ProfilePopup";
import { logoutSuccess } from "../../Redux/reducers/authSlice";
import { useAppDispatch } from "../../Redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/rootReducer";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((state: RootState) => state.auth);


  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleImageClick = () => {
    setIsPopupVisible(true);
  };

  const handleLogOut = () => {
    dispatch(logoutSuccess());
  };

  return (
    <>
      <div>
        <div>
          <div className="userWrapper flex-col flex bg-[#fff] p-[20px] rounded-[20px] min-h-[400px] md:flex-row">
            <div className="userProfile w-[100%]  md:w-[30%] cursor-pointer">
              {/* <img
                src={require("../../assets/image/user.jpg")}
                alt="userprofile"
                onClick={handleImageClick}
                className="w-[100%] h-[100%] object-cover"
              /> */}
            </div>
            <div className="userContent flex w-[100%] flex-col justify-between md:w-[70%] md:pl-[70px] md:pr-[20px]">
              <div className="flex justify-between items-start">
                <div className="userTitle">
                  <h1 className="heading-one font-semibold">Welcome {user.firstName}!</h1>
                </div>
                <div className="buttonArea">
                  <button
                    type="button"
                    onClick={handleLogOut}
                    className="bg-[#3B82F6] text-[#fff] px-[30px] py-[12px] rounded-[10px] text-[18px] font-normal"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ProfilePopup
            isOpen={isPopupVisible}
            setIsPopupVisible={setIsPopupVisible}
          >
            <div className="formWrapper flex h-[500px] flex-col max-w-[1300px] py-[20px] m-auto md:py-[50px] gap-[20px] md:flex-row md:h-[unset] overflow-y-auto">
              <div className="w-[100%] md:w-[50%]">
                <ProfileForm />
              </div>
              <div className="w-[100%] md:w-[50%]">
                <ProfilePassword />
              </div>
            </div>
          </ProfilePopup>
        </div>
      </div>
    </>
  );
};

export default Profile;
