import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import store from '../Redux/store';


export const withoutAuthAxios = (): AxiosInstance => {
    return axios.create({
      baseURL:  process.env.REACT_APP_BASEURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
  
  export const authAxios = (): AxiosInstance => {

    const token = true;
    const instance = axios.create({
      baseURL:  process.env.REACT_APP_BASEURL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
  
    instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        let token = store.getState().auth.accessToken;
        const newToken = token;
        if (newToken && config.headers) {
          config.headers.Authorization = `Bearer ${newToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
         console.log("Unauthorized error")
        }
        return Promise.reject(error);
      }
    );
  
    return instance;
  };
