import React from 'react';

// Define the types for the props
interface ConfirmDeletePopupProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmDeletePopup: React.FC<ConfirmDeletePopupProps> = ({ isVisible, onClose, onConfirm }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-10 rounded shadow-lg w-100">
        <h2 className="text-lg text-center font-semibold mb-4">Confirm Deletion</h2>
        <p className="mb-4">Are you sure you want to delete this Executive?</p>
        <div className="flex justify-center gap-4 mt-[30px]">
          <button
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeletePopup;
