import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { withoutAuthAxios } from "../../../config/config";
import { loginSuccess } from "../../../Redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../Redux/store";
import { trimObjValues } from "../../../Helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/rootReducer";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, accessToken } = useSelector((state: RootState) => state.auth);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (accessToken && user) {
      navigate("/");
    }
  }, [accessToken, user, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleForgotPasswordEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordEmail(e.target.value);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]{2,}@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const requestPayload = trimObjValues(userInput);
    setLoading(true);
    try {
      const response = await withoutAuthAxios().post(
        "/executives/login",
        requestPayload
      );
      const resData = response.data.AuthenticationResult;
      const user = response.data.user;
      if (resData) {
        const payload = {
          user: user,
          accessToken: resData.AccessToken,
          idToken: resData.IdToken,
          refreshToken: resData.RefreshToken,
        };
        dispatch(loginSuccess(payload));
        toast.success("Logged in successfully");
        navigate("/");
      } else if (
        response.data.message === "New password required" &&
        response.data.session
      ) {
        navigate(
          `/change-password?token=${encodeURIComponent(response.data.session)}`,
          { state: { email: requestPayload.email } }
        );
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to login";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError(null);
    setMessage("");

    if (!validateEmail(forgotPasswordEmail)) {
      setError("Please enter a valid email address.");
      return;
    }

    const payload = { email: forgotPasswordEmail };
    const requestPayload = trimObjValues(payload);
    setLoading(true);

    try {
      const response = await withoutAuthAxios().post(
        "/executives/forget-password",
        requestPayload
      );
      console.log("response", response);
      localStorage.setItem("resetEmail", forgotPasswordEmail);
      setMessage("We have created a temporary password and sent it to your inbox. Please use this password to log in and update your credentials.");
    } catch (error: any) {
      let errMsg = "Failed to send mail";
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          errMsg = error.response.data.message;
        } else if (error.response.data.error) {
          errMsg = error.response.data.error;
        }
      }
      setError(errMsg);
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBackToLogin = () => {
    setMessage("");
    setError(null); 
    setShowForgotPassword(false);
  };

  return (
    <section className="min-h-[100vh] px-[15px]">
      <div className="wrapper min-h-[100vh] flex flex-col gap-[30px] items-center justify-center max-w-[580px] m-auto">
        <img
          src={require("../../../assets/image/SiteLogo.png")}
          alt="Logo"
          className="max-w-[180px]"
        />
        <div className="w-[100%] bg-[#BEDDFC] py-[70px] px-[15px]">
          <div className="max-w-[400px] m-auto flex flex-col justify-center min-h-[350px]">
            <div className="title text-2xl mb-4 noto text-[30px] font-semibold text-center">
              {showForgotPassword ? "Forgot Password" : "Welcome!"}
            </div>
            {showForgotPassword ? (
              <form
                onSubmit={handleResetPasswordSubmit}
                className="forgotpasswordform my-[30px]"
              >
                {error && (
                  <div className="text-red-600 bg-red-100 border border-red-600 p-4 mb-4 rounded">
                    {error}
                  </div>
                )}
                {message ? (
                  <div className="text-white-600 bg-blue-100 border border-blue-600 p-4 mb-4 rounded">
                    {message}
                  </div>
                ) : (
                  <>
                    <div className="mb-4 flex gap-[5px] items-center bg-[#fff] rounded-[5px] p-[15px] shadow">
                      <span className="mr-2">
                        <img
                          src={require("../../../assets/image/mail.png")}
                          alt="mail"
                        />
                      </span>
                      <span className="flex-1">
                        <input
                          id="forgot-password-email"
                          required
                          placeholder="Enter Your Email"
                          className="w-full"
                          type="email"
                          value={forgotPasswordEmail}
                          onChange={handleForgotPasswordEmailChange}
                        />
                      </span>
                    </div>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white p-[15px] rounded-md w-full"
                    >
                      {loading ? "Processing" : "Send Temporary Password"}
                    </button>
                  </>
                )}
                <div onClick={handleBackToLogin} className={`mt-4 cursor-pointer text-center mx-auto ${message ? 'bg-blue-500 text-white rounded-[5px] px-4 py-3':'w-fit'}`}>
                  <span>
                    Back to Login
                  </span>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit} className="loginform my-[30px]">
                <div className="mb-4 flex gap-[5px] items-center bg-[#fff] rounded-[5px] p-[15px] shadow">
                  <span className="mr-2">
                    <img
                      src={require("../../../assets/image/mail.png")}
                      alt="mail"
                    />
                  </span>
                  <span className="flex-1">
                    <input
                      id="email"
                      required
                      type="email"
                      placeholder="Enter Your Email"
                      className="w-full"
                      value={userInput.email}
                      onChange={handleChange}
                      name="email"
                    />
                  </span>
                </div>

                <div className="mb-4 flex gap-[5px] items-center bg-[#fff] rounded-[5px] p-[15px] shadow">
                  <span className="mr-2">
                    <img
                      src={require("../../../assets/image/password.png")}
                      alt="password"
                    />
                  </span>
                  <span className="flex-1">
                    <input
                      id="password"
                      required
                      placeholder="Password"
                      className="w-full"
                      type={passwordVisible ? "text" : "password"}
                      value={userInput.password}
                      name="password"
                      onChange={handleChange}
                    />
                  </span>
                  <span
                    className="showpassword cursor-pointer ml-2"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? (
                      <img
                        src={require("../../../assets/image/hideeye.png")}
                        alt="hideeye"
                        className="showeye"
                      />
                    ) : (
                      <img
                        src={require("../../../assets/image/showeye.png")}
                        alt="showeye"
                        className="showeye"
                      />
                    )}
                  </span>
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-[15px] rounded-md w-full"
                >
                  {loading ? "Processing" : "Login"}
                </button>
                <div className="mt-4 flex justify-between">
                  <span
                    className="cursor-pointer"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    Forgot Password?
                  </span>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
