
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  user: any;
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
  session: string | null;
  email: string | null;
  userDetails: any | null; 
  patientDetails: any | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  accessToken: null,
  idToken: null,
  refreshToken: null,
  session: null,
  email: null,
  userDetails: null,
  patientDetails: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<{ user: any; accessToken: string; idToken: string; refreshToken: string }>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
     newPasswordRequired: (state, action: PayloadAction<{ session: string;  email: string }>) => {
      state.session = action.payload.session;
      state.email = action.payload.email;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setPatientDetails: (state, action: PayloadAction<any>) => {
      state.patientDetails = {
        ...state.patientDetails,
        ...action.payload, 
      };
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
      state.userDetails =null;
      state.patientDetails =null;
    },
  },
});

export const { loginSuccess, logoutSuccess, newPasswordRequired, setUserDetails, setPatientDetails} = authSlice.actions;



export default authSlice.reducer;