import { combineReducers, AnyAction } from '@reduxjs/toolkit';
import auth from './reducers/authSlice';
import app from './reducers/appSlice';
import leads from './reducers/leadsSlice'; 

const appReducer = combineReducers({
    auth,
    app,
    leads
})

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === 'auth/logout') {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
