import React, { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { State } from "country-state-city";
import { StepProps } from "../../../config/types";
import { useSelector } from "react-redux";
// import { RootState } from "../../store/store";

interface Step23Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step23: React.FC<Step23Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const patientDetailsStore = useSelector(
    (state: any) => state?.auth?.patientDetails
  );

  const getInitialValue = (formField: string, storeField: string) => {
    return (
      (patientDetailsStore && patientDetailsStore[storeField]) ||
      formValues?.shipping?.[formField] ||
      ""
    );
  };

  console.log(patientDetailsStore, "patientDetailsStore");

  const [shipping, setShipping] = useState({
    line1: getInitialValue("shipping_address_line1", "line1"),
    line2: getInitialValue("shipping_address_line2", "line2"),
    city: getInitialValue("shipping_city", "city"),
    state: getInitialValue("shipping_state", "state"),
    zipcode: getInitialValue("shipping_zipcode", "zip"),
  });

  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const states = State.getStatesOfCountry("US").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  }, []);

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (shipping.line1.trim() === "") {
      newErrors.line1 = "Line 1 is required.";
    }
    if (shipping.city.trim() === "") {
      newErrors.city = "City is required.";
    }
    if (shipping.state.trim() === "") {
      newErrors.state = "State is required.";
    }
    if (shipping.zipcode.trim() === "") {
      newErrors.zipcode = "Zipcode is required.";
    } else if (!/^\d{5}$/.test(shipping.zipcode)) {
      newErrors.zipcode = "Zipcode must be a 5-digit number.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const isStepValid = validate();
    setStepValid(isStepValid);

    handleChange({
      target: {
        name: "shipping",
        value: shipping,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [shipping]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = e.target;
    setShipping((prevShipping) => ({
      ...prevShipping,
      [field]: value,
    }));
  };

  const handleSelectChange = (
    selectedOption: SingleValue<{ value: string; label: string }>
  ) => {
    const value = selectedOption ? selectedOption.value : "";
    setShipping((prevShipping) => ({
      ...prevShipping,
      state: value,
    }));
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        23. Please provide your shipping address:
      </p>
      <div className="mt-4">
        <label className="text-[#000000] text-[16px] font-[400]">Line 1</label>
        <input
          type="text"
          name="shipping_address_line1"
          value={shipping.line1}
          onChange={(e) => handleInputChange(e, "line1")}
          className="w-full p-2 border border-gray-300 rounded mt-2 mb-[12px]"
        />
        {errors.line1 && <p className="text-red-500">{errors.line1}</p>}
      </div>
      <div className="mt-4">
        <label className="text-[#000000] text-[16px] font-[400]">
          Line 2 (Optional)
        </label>
        <input
          type="text"
          name="shipping_address_line2"
          value={shipping.line2}
          onChange={(e) => handleInputChange(e, "line2")}
          className="w-full p-2 border border-gray-300 rounded mt-2 mb-[12px]"
        />
      </div>
      <div className="mt-4">
        <label className="text-[#000000] text-[16px] font-[400]">City</label>
        <input
          type="text"
          name="shipping_city"
          value={shipping.city}
          onChange={(e) => handleInputChange(e, "city")}
          className="w-full p-2 border border-gray-300 rounded mt-2 mb-[12px]"
        />
        {errors.city && <p className="text-red-500">{errors.city}</p>}
      </div>
      <div className="mt-4">
        <label className="text-[#000000] text-[16px] font-[400]">State</label>
        <Select
          options={stateOptions}
          value={stateOptions.find((option) => option.value === shipping.state)}
          onChange={handleSelectChange}
          className="w-full mt-2 custom-dropdown-border"
        />
        {errors.state && <p className="text-red-500">{errors.state}</p>}
      </div>
      <div className="mt-4">
        <label className="text-[#000000] text-[16px] font-[400]">Zipcode</label>
        <input
          type="text"
          name="shipping_zip"
          value={shipping.zipcode}
          onChange={(e) => handleInputChange(e, "zipcode")}
          className="w-full p-2 border border-gray-300 rounded mt-2 mb-[12px]"
        />
        {errors.zipcode && <p className="text-red-500">{errors.zipcode}</p>}
      </div>
    </div>
  );
};

export default Step23;
