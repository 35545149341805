import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

const Step6: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [address, setAddress] = useState(
    formValues.q6_permanent_address?.[0] || ""
  );

  useEffect(() => {
    const isStepValid = address.trim() !== "";
    setStepValid(isStepValid);

    // Update the form values
    handleChange({
      target: {
        name: "q6_permanent_address",
        value: [address],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [address, setStepValid]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        6. Please provide your permanent address
      </p>
      <div className="flex flex-col gap-5 items-start my-3 mb-7 w-full">
        <textarea
          name="permanent_address"
          value={address}
          onChange={handleInputChange}
          rows={4}
          className="w-full min-h-[100px] rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter your full address here"
        ></textarea>
      </div>
    </div>
  );
};

export default Step6;
