import React, { useState } from 'react';
import ExecutiveFormModal from '../common/forms/ExecutiveFormModal';
import ProfilePopup from '../profile/ProfilePopup';

const CustomerDetails: React.FC = () => {
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

    const handleImageClick = () => {
        setIsPopupVisible(true);
    };

    return (
        <div className="cursor-pointer">
            <div onClick={handleImageClick} className="flex flex-col justify-between px-[20px] md:px-[30px] bg-[#fff] items-center p-[20px] rounded-[20px] min-h-[300px] lg:px-[30px]">
                <div className="patientTittle w-[100%] text-left text-[#000]">
                    <span className="text-[#484848] text-[18px]">Add Customer Executive Details</span>
                </div>
                <div className="w-[100%] mt-auto md:mt-[unset] flex justify-between">
                    <div>
                        <span className="text-[#000] font-medium text-[25px]">+ Add Executive</span>
                    </div>
                    <div className="cursor-pointer">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1V17M1 9H17" stroke="#2F2F38" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <ProfilePopup isOpen={isPopupVisible} setIsPopupVisible={setIsPopupVisible}>
                <div className="formWrapper justify-center flex h-[500px] flex-col max-w-[1300px] py-[20px] m-auto md:py-[50px] gap-[20px] md:flex-row md:h-[unset] overflow-y-auto">
                    <div className="w-[100%] md:w-[50%]">
                        <ExecutiveFormModal setIsPopupVisible={setIsPopupVisible} />
                    </div>
                </div>
            </ProfilePopup>
        </div>
    );
};

export default CustomerDetails;
