"use client";
import React, { useEffect, useState } from "react";
import * as NewSteps from "./intake-step-form";
import IsLoggedinHOC from "./common/IsLoggedinHOC";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/rootReducer";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import PaymentLinkModal from "./common/Popup/PaymentLinkModal";

interface NavigationButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const newSteps = [
  NewSteps.Step1,
  NewSteps.Step2,
  NewSteps.Step3,
  NewSteps.Step4,
  NewSteps.Step5,
  NewSteps.Step6,
  NewSteps.Step7,
  NewSteps.Step8,
  NewSteps.Step9,
  NewSteps.Step10,
  NewSteps.Step11,
  NewSteps.Step12,
  NewSteps.Step13,
  NewSteps.Step14,
  NewSteps.Step15,
  NewSteps.Step16,
  NewSteps.Step17,
  NewSteps.Step18,
  NewSteps.Step19,
  NewSteps.Step20,
  NewSteps.Step21,
  NewSteps.Step22,
  NewSteps.Step23,
];

const syncRequiredConditions = [
  "Alcohol/substance use disorder",
  "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2",
  "Tumor/infection in brain/spinal cord",
  "Elevated resting heart rate (tachycardia)",
  "Coronary artery disease or heart attack/stroke in last 2 years",
  "Congestive heart failure",
  "Hospitalization within the last 1 year",
];

const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
        label === "Back" ? "bg-white text-[#3B82F6]" : "bg-[#3B82F6] text-white"
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {label}
    </button>
  );
};

const AddPatientMoreDetail: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { patientDetail } = useSelector((state: RootState) => state.app);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentLinkModal, setPaymentLinkModal] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<string>("");

  const [formValues, setFormValues] = useState<any>({
    patient_id: patientDetail.id,
    formReferenceId: 1172922,
    mwl_modality: "async_visit",
    shipping_address_line_1: "",
    shipping_city: "delhi",
    shipping_state: "IA",
    shipping_zip: "11009",
  });

  const calculateAge = (dob: string): number => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const updateMwlModality = (
    age: number,
    bmi: number | null,
    prescriptionMedications: string[],
    opiatePainMedications: string[],
    conditions: string[],
    bloodPressure: string,
    heartRate: string
  ) => {
    let mwl_modality = "async_visit";
    if (
      (age >= 65 && age <= 74) ||
      (bmi !== null && bmi >= 27 && bmi <= 30) ||
      (prescriptionMedications.length > 0 &&
        prescriptionMedications[0] !== "No") ||
      (opiatePainMedications.length > 0 && opiatePainMedications[0] !== "No") ||
      conditions.some((condition) =>
        syncRequiredConditions.includes(condition)
      ) ||
      (bloodPressure.length > 0 &&
        bloodPressure[0] === "≥140/90 (High Stage 2)") ||
      (heartRate.length > 0 &&
        heartRate[0] === "101-110 beats per minute (Slightly Fast)") ||
      (heartRate.length > 0 && heartRate[0] === ">110 beats per minute (Fast)")
    ) {
      mwl_modality = "sync_visit";
    } else if (age < 18) {
      mwl_modality = "async_visit";
    }

    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      mwl_modality,
    }));
  };

  useEffect(() => {
    if (
      formValues.q1_what_is_your_date_of_birth ||
      formValues.q2_bmi_category ||
      formValues.q9_prescription_medications_to_lose_weight_before ||
      formValues.q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs ||
      formValues.q15_conditions ||
      formValues.q17_patient_blood_pressure_range ||
      formValues.q18_patient_heart_rate
    ) {
      const age = calculateAge(formValues.q1_what_is_your_date_of_birth?.[0]);
      const bmi = formValues.q2_bmi_category
        ? parseFloat(formValues.q2_bmi_category?.[0])
        : null;
      const prescriptionMedications =
        formValues.q9_prescription_medications_to_lose_weight_before || [];
      const opiatePainMedications =
        formValues.q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs ||
        [];
      const conditions = formValues.q15_conditions || [];
      const bloodPressure = formValues.q17_patient_blood_pressure_range || "";
      const heartRate = formValues.q18_patient_heart_rate || "";
      updateMwlModality(
        age,
        bmi,
        prescriptionMedications,
        opiatePainMedications,
        conditions,
        bloodPressure,
        heartRate
      );
    }
  }, [
    formValues.q1_what_is_your_date_of_birth,
    formValues.q2_bmi_category,
    formValues.q9_prescription_medications_to_lose_weight_before,
    formValues.q10_have_you_taken_opiate_pain_medications_and_or_opiate_based_street_drugs,
    formValues.q15_conditions,
    formValues.q17_patient_blood_pressure_range,
    formValues.q18_patient_heart_rate,
  ]);

  const [isStepValid, setIsStepValid] = useState(true);

  const handleNext = () => {
    if (!isStepValid) {
      console.log("Please fill all the required fields or check the boxes.");
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrev = () => setCurrentStep((prev) => prev - 1);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // const requestPayload = trimObjValues(formData);
    const payload = formValues;
    setLoading(true);
    try {
      const response = await authAxios().post("/refill-intake", payload);
      const resData = response?.data;
      const sucMsg = resData?.message || "Data add successfully";
      toast.success(sucMsg);
      generatePaymentLink();
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to add more info patient";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const generatePaymentLink = async () => {
    setLoading(true);
    const payload = {
      patientId: Number(patientDetail.id),
      planPriceId: "3-month-demo-USD-Monthly",
      email: patientDetail.email,
      phoneNumber: patientDetail?.phone_number,
    };
    try {
      const response = await authAxios().post(
        "/payment/generate-payment-link",
        payload
      );
      const resData = response.data;
      const toastMsg = resData.message || "payment link generated successfully";
      if (resData.url) {
        setPaymentLinkModal(true);
        setPaymentLink(resData.url);
        toast.success(toastMsg);
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to generate payment link";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const StepComponent = newSteps[currentStep];
  const progressPercentage = ((currentStep + 1) / newSteps.length) * 100;

  return (
    <>
      <div className="bg-[#3B82F6] h-full relative">
        <div className="logo--header px-3 py-6 flex items-center justify-between bg-white">
          <div className="w-full ">
            <Link
              to="/"
              className="w-[150px] lg:w-[200px] block sm:mx-auto mr-5"
            >
              <img
                src={"../assets/images/SiteLogo.png"}
                width={170}
                height={50}
                alt="logo"
                className="w-full"
              />
            </Link>
          </div>
        </div>
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className="px-3 max-w-[1522px] w-[95%] mx-auto py-10 bg-white rounded-md mt-[50px] mb-[50px]">
          <form onSubmit={handleSubmit}>
            <StepComponent
              currentStep={currentStep}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleChange={handleChange}
              formValues={formValues}
              setStepValid={setIsStepValid}
            />
            <div className={`mt-8 w-full bg-white py-5 px-3 left-0 `}>
              <div
                className={`flex max-w-[1400px] w-full mx-auto ${
                  currentStep > 0 ? "justify-between" : "justify-end"
                }`}
              >
                {currentStep > 0 && (
                  <NavigationButton label="Back" onClick={handlePrev} />
                )}
                {currentStep < newSteps.length - 1 && (
                  <NavigationButton
                    label="Next"
                    onClick={handleNext}
                    disabled={!isStepValid}
                  />
                )}
                {currentStep === newSteps.length - 1 && (
                  <button
                    type="submit"
                    disabled={loading}
                    className={`min-h-[45px] rounded-[5px] min-w-[102px] border ${
                      loading
                        ? "border-gray-400 bg-gray-400 cursor-not-allowed"
                        : "border-[#3B82F6] bg-[#3B82F6]"
                    } text-white`}
                  >
                    {loading ? "Processing" : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </form>
          <PaymentLinkModal
            isOpen={paymentLinkModal}
            paymentUrl={paymentLink}
            onRequestClose={() => setPaymentLinkModal(false)}
          />
        </div>
      </div>
    </>
  );
};

export default IsLoggedinHOC(AddPatientMoreDetail);
