import React from 'react';
import ExecutiveList from '../../SuperExecutive/ExecutiveList';
import SearchExecutive from '../../SuperExecutive/SearchExecutive';
import Logo from '../Logo';
import withAuth from '../withAuth';

const CustomersExecutive: React.FC = () => {
  return (
    <div className="search-area max-w-[100%] px-[15px] py-[70px] min-h-[100vh] lg:max-w-[90%] m-auto">
      <Logo />
      {/* <SearchExecutive /> */}
      <ExecutiveList />
    </div>
  );
};

export default withAuth(CustomersExecutive);
