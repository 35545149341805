import React from 'react';

interface ProfilePopupProps {
  isOpen: boolean;
  setIsPopupVisible: (isVisible: boolean) => void;
  children: React.ReactNode;
}

const ProfilePopup: React.FC<ProfilePopupProps> = ({ isOpen, setIsPopupVisible, children }) => {

  const handleCloseModal = () => {
    setIsPopupVisible(false);
  }

  return (
    <div className={`popup ${isOpen ? 'popup-open' : 'popup-close'}`}>
      <div className="popup-content">
        <div className="close-btn" onClick={handleCloseModal}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0188 1.00612L1.00658 23.0184M1.00658 1.00612L23.0188 23.0184"
              stroke="#2F2F38"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};

export default ProfilePopup;
