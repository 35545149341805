import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step19Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step19: React.FC<Step19Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [goalWeightOrBMI, setGoalWeightOrBMI] = useState<string>(
    formValues.q19_goal_weight_or_bmi?.[0] || ""
  );

  useEffect(() => {
    const isStepValid = goalWeightOrBMI.trim() !== "";
    setStepValid(isStepValid);

    handleChange({
      target: {
        name: "q19_goal_weight_or_bmi",
        value: [goalWeightOrBMI],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [goalWeightOrBMI, setStepValid]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGoalWeightOrBMI(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px]">
      <p className="text-gray-900 text-lg  mb-5">
        19. What is your goal weight or body mass index (BMI)? Please note that
        while this information is helpful, medical decisions will be made by the
        clinicians keeping clinical evidence and patient safety at the
        forefront.
      </p>

      <div className="mt-4">
        <textarea
          name="q19_goal_weight_or_bmi"
          value={goalWeightOrBMI}
          onChange={handleInputChange}
          className="w-full min-h-[100px] rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={5}
          placeholder="Enter your goal weight or BMI here"
        />
      </div>
    </div>
  );
};

export default Step19;
