import React, { useState, ChangeEvent, FormEvent } from "react";
import { withoutAuthAxios } from "../../../config/config";
import { loginSuccess } from "../../../Redux/reducers/authSlice";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";
import Logo from "../common/Logo";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../Redux/store";
import { trimObjValues } from "../../../Helper";

const ChangePassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const sessionToken = searchParams.get("token");
  const location = useLocation();
  const userEmail = location.state?.email;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    email: userEmail,
    password: "",
    token: sessionToken,
  });

  console.log("sessionToken", sessionToken);
  console.log("location", location);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const requestPayload = trimObjValues(userInput);
    setLoading(true);
    try {
      const response = await withoutAuthAxios().post(
        "executives/new-password-for-executive",
        requestPayload
      );
      console.log("response", response);
      const resData = response.data;
      toast.success(resData.message);
      if (resData.message === "New password updated successfully") {
        navigate("/login");
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to change password";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <section className="min-h-[100vh] px-[15px]">
      <div className="wrapper min-h-[100vh] flex flex-col gap-[30px] items-center justify-center max-w-[580px] m-auto">
        <img
          src={require("../../../assets/image/SiteLogo.png")}
          alt="Logo"
          className="max-w-[180px]"
        />
        <div className="w-[100%] bg-[#BEDDFC] py-[70px] px-[15px]">
          <div className="max-w-[400px] m-auto flex flex-col justify-center min-h-[350px]">
            <div className="title text-2xl mb-4 noto text-[30px] font-semibold text-center">
              Change Password
            </div>
            <form onSubmit={handleSubmit} className="loginform my-[30px]">
              <div className="mb-4 flex gap-[5px] items-center bg-[#fff] rounded-[5px] p-[15px] shadow">
                <span className="mr-2">
                  <img
                    src={require("../../../assets/image/mail.png")}
                    alt="mail"
                  />
                </span>
                <span className="flex-1">
                  <input
                    id="email"
                    required
                    disabled
                    type="email"
                    placeholder="Enter Your Email"
                    className="w-full"
                    value={userInput.email}
                    onChange={handleChange}
                    name="email"
                  />
                </span>
              </div>
              <div className="mb-4 flex gap-[5px] items-center bg-[#fff] rounded-[5px] p-[15px] shadow">
                <span className="mr-2">
                  <img
                    src={require("../../../assets/image/password.png")}
                    alt="password"
                  />
                </span>
                <span className="flex-1">
                  <input
                    id="password"
                    required
                    placeholder="New Password"
                    className="w-full"
                    type={passwordVisible ? "text" : "password"}
                    value={userInput.password}
                    name="password"
                    onChange={handleChange}
                  />
                </span>
                <span
                  className="showpassword cursor-pointer ml-2"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? (
                    <img
                      src={require("../../../assets/image/hideeye.png")}
                      alt="hideeye"
                      className="showeye"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/image/showeye.png")}
                      alt="showeye"
                      className="showeye"
                    />
                  )}
                </span>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 text-white p-[15px] rounded-md w-full"
              >
                {loading ? "Processing" : "Reset"}
              </button>
              <div className="mt-[30px] cursor-pointer text-center">
                <Link to="/login">Back to login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
