import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step11Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step11: React.FC<Step11Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(
    formValues.q11_prior_surgeries?.[0] || null
  );
  const [surgeryDetails, setSurgeryDetails] = useState<string>(
    formValues
      .q11_please_list_all_your_prior_bariatric_weight_loss_abdominal_and_pelvic_surgeries?.[0] ||
      ""
  );

  useEffect(() => {
    const isStepValid =
      selectedOption !== null &&
      (selectedOption === "Yes" ? surgeryDetails.trim() !== "" : true);
    setStepValid(isStepValid);

    if (selectedOption) {
      handleChange({
        target: {
          name: "q11_prior_surgeries",
          value: [selectedOption],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }

    if (selectedOption === "Yes" && surgeryDetails.trim() !== "") {
      handleChange({
        target: {
          name: "q11_please_list_all_your_prior_bariatric_weight_loss_abdominal_and_pelvic_surgeries",
          value: [surgeryDetails],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [selectedOption, surgeryDetails, setStepValid]);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    if (value !== "Yes") {
      setSurgeryDetails("");
      handleChange({
        target: {
          name: "q11_please_list_all_your_prior_bariatric_weight_loss_abdominal_and_pelvic_surgeries",
          value: [""],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleSurgeryDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSurgeryDetails(e.target.value);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        11. Have you had prior bariatric (weight loss) surgery or any
        abdominal/pelvic surgeries?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {selectedOption === "Yes" && (
        <div className="mt-4">
          <p className="text-[#000000] text-[18px] font-[400] text-left">
            Please list all your prior bariatric (weight loss), abdominal, and
            pelvic surgeries. Please include date range and type of surgery.
          </p>
          <textarea
            name="q11_please_list_all_your_prior_bariatric_weight_loss_abdominal_and_pelvic_surgeries"
            value={surgeryDetails}
            onChange={handleSurgeryDetailsChange}
            className="w-full p-2 border border-gray-300 rounded mt-2"
            rows={5}
            placeholder="Please specify if any"
          />
        </div>
      )}
    </div>
  );
};

export default Step11;
