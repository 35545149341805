import React, { useState, ChangeEvent, FormEvent } from 'react';

const ProfilePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    // Perform further validation if needed
    // e.g., check if old password is correct, check password strength, etc.

    // If all validations pass, proceed with password update logic
    // e.g., make API call to update password

    setSuccess('Password updated successfully');
  };

  const handleOldPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className='h-[100%]'>
      <div className='bg-[#EAEBED] rounded-[10px] px-[33px] py-[38px] h-[100%]'>
        <div>
          <h4 className='text-[25px] font-medium pb-[20px]'>Change Password</h4>
        </div>
        <div className='userForm'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-wrap flex-col gap-[10px] justify-center'>
              <div className='w-[100%] bg-[#fff] p-[20px] rounded-[5px]'>
                <input
                  type='password'
                  id='oldPassword'
                  placeholder='Old Password'
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  required
                />
              </div>
              <div className='w-[100%] bg-[#fff] p-[20px] rounded-[5px]'>
                <input
                  type='password'
                  id='newPassword'
                  placeholder='New Password'
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  required
                />
              </div>
              <div className='w-[100%] bg-[#fff] p-[20px] rounded-[5px]'>
                <input
                  type='password'
                  id='confirmPassword'
                  placeholder='Confirm Password'
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}
              <button
                type='submit'
                className='bg-[#3B82F6] text-[#fff] px-[30px] py-[12px] rounded-[8px] text-[18px] font-normal mt-[1rem]'
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePassword;
