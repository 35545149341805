import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

const Step1: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [dob, setDob] = useState<string>(
    formValues.q1_what_is_your_date_of_birth?.[0] || ""
  );
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const selectedDate = new Date(dob);
    const today = new Date();
    const EighteenYearsAgo = new Date();
    EighteenYearsAgo.setFullYear(today.getFullYear() - 18);
    const isValidDob =
      dob && !isNaN(selectedDate.getTime()) && selectedDate <= EighteenYearsAgo;

    if (!isValidDob && dob) {
      setError("Date of birth must be at least 18 years");
    } else {
      setError("");
    }

    setStepValid(Boolean(isValidDob));

    updateFormValues("q1_what_is_your_date_of_birth", [dob]);
  }, [dob, setStepValid]);

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDob(value);
    updateFormValues("q1_what_is_your_date_of_birth", [value]);
  };

  const updateFormValues = (name: string, value: string[]) => {
    const customEvent = {
      target: {
        name,
        value,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;

    handleChange(customEvent);
  };

  // Calculate the date 18 years ago from today
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const maxDate = eighteenYearsAgo.toISOString().split("T")[0];

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        1. What is your date of birth?
      </p>
      <div className="flex flex-col gap-[20px] items-start my-3 mb-7">
        <input
          type="date"
          value={dob}
          onChange={handleDobChange}
          className={`min-h-[45px] rounded-[5px] min-w-[200px] border px-2 ${
            error ? "border-red-500" : "border-[#3B82F6]"
          } focus:outline-none focus:ring-2 focus:ring-[#3B82F6]`}
          max={maxDate}
        />
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default Step1;
