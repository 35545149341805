import React, { useEffect, useState } from 'react';
import CheckoutExecutiveDetail from '../Components/SuperExecutive/CheckoutExecutiveDetail';
import CustomerDetails from '../Components/SuperExecutive/CustomerDetails';
import ProfileExecutive from '../Components/SuperExecutive/ProfileExecutive';
import { authAxios } from '../../config/config';
import { toast } from 'react-toastify';
import { logoutSuccess } from '../../Redux/reducers/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTotalExecutive, setTotalSubmission } from '../../Redux/reducers/leadsSlice';
import { RootState } from '../../Redux/rootReducer';


const SuperAdminDash: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Access Redux state
  const totalExecutive = useSelector((state: RootState) => state.leads.totalExecutive);
  const totalSubmission = useSelector((state: RootState) => state.leads.totalSubmission);
  
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    getTotalCount();

    }, []);

  const getTotalCount = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get('/leads/count/executives-leads');
      const resData = response?.data;
      const executives = resData.totalExecutives;
      const submissions = resData.totalLeads;

      // Dispatch actions to update Redux store
      dispatch(setTotalExecutive(executives));
      dispatch(setTotalSubmission(submissions));
    } catch (error: any) {
      let errMsg = '';
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response?.data?.message) {
        errMsg = error.response?.data?.message;
      } else {
        errMsg = 'Failed to fetch executive list data';
      }
      if (errMsg === 'Unauthorized') {
        dispatch(logoutSuccess());
        navigate('/login');
      } else {
        toast.error(errMsg);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#EAEBED] px-[15px] py-[50px] min-h-[100vh] flex items-center justify-center">
      <div className="flex flex-col flex-wrap gap-[20px] lg:flex-row w-[100%] lg:w-[95%]">
        <div className="w-[100%] lg:w-[calc(60%-10px)] text-center">
          <ProfileExecutive />
        </div>
        <div className="w-[100%] lg:w-[calc(40%-10px)] text-center">
          <div className="flex flex-col gap-[20px]">
            <div>
              <div className="h-[100%]">
                <div className="bg-[#3B82F6] text-[#fff] px-[20px] py-[20px] rounded-[20px] h-[100%] flex flex-col justify-between lg:px-[30px] lg:py-[30px] min-h-[150px]">
                  <div className="subHead text-left">
                    <h4 className="text-[25px] font-medium leading-[1.1]">
                      Total Customer Executive
                    </h4>
                  </div>
                  <div className="text-right">
                    <span className="counter top-[0px] font-medium text-[clamp(30px,5vw,100px)] leading-[1.1] relative lg:top-[20px]">
                      {totalExecutive}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="h-[100%]">
                <div className="bg-[#3B82F6] text-[#fff] px-[20px] py-[20px] rounded-[20px] h-[100%] flex flex-col justify-between lg:px-[30px] lg:py-[30px] min-h-[150px]">
                  <div className="subHead text-left">
                    <h4 className="text-[25px] font-medium leading-[1.1]">
                      Total Submission
                    </h4>
                  </div>
                  <div className="text-right">
                    <span className="counter top-[0px] font-medium text-[clamp(30px,5vw,100px)] leading-[1.1] relative lg:top-[20px]">
                      {totalSubmission}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[calc(50%-10px)] text-center">
          <CustomerDetails />
        </div>
        <div className="w-[100%] lg:w-[calc(50%-10px)] text-center">
          <CheckoutExecutiveDetail />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDash;
