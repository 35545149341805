import React, { useState, useEffect } from "react";
import { StepProps } from "../../../config/types";

interface Step21Props extends StepProps {
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step21: React.FC<Step21Props> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [allergyDetails, setAllergyDetails] = useState<string>(
    formValues.q21_allergy_details?.[0] || "No"
  );

  const selectedOption = allergyDetails !== "No" ? "Yes" : "No";

  useEffect(() => {
    const isStepValid = selectedOption === "No" || allergyDetails.trim() !== "";
    setStepValid(isStepValid);

    handleChange({
      target: {
        name: "q21_medication_allergies",
        value: [selectedOption],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);

    handleChange({
      target: {
        name: "q21_allergy_details",
        value: [allergyDetails],
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [selectedOption, allergyDetails, setStepValid]);

  const handleOptionChange = (value: string) => {
    if (value === "No") {
      setAllergyDetails("No");
    } else if (value === "Yes" && allergyDetails === "No") {
      setAllergyDetails("");
    }
  };

  const handleAllergyDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAllergyDetails(e.target.value);
    if (selectedOption === "Yes") {
      handleChange({
        target: {
          name: "q21_allergy_details",
          value: [e.target.value],
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        21. Do you have any medication allergies?
      </p>
      <div className="flex flex-row gap-[20px] items-center my-3 mb-7">
        <button
          type="button"
          onClick={() => handleOptionChange("Yes")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "Yes"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          Yes
        </button>
        <button
          type="button"
          onClick={() => handleOptionChange("No")}
          className={`min-h-[45px] rounded-[5px] min-w-[102px] border border-[#3B82F6] ${
            selectedOption === "No"
              ? "bg-[#3B82F6] text-white"
              : "text-[#000000] bg-[#ffffff]"
          }`}
        >
          No
        </button>
      </div>

      {selectedOption === "Yes" && (
        <div className="mt-4">
          <p className="text-[#000000] text-[18px] font-[400] text-left">
            Please list your medication allergies
          </p>
          <textarea
            name="q21_allergy_details"
            value={allergyDetails === "No" ? "" : allergyDetails}
            onChange={handleAllergyDetailsChange}
            className="w-full p-2 border border-gray-300 rounded mt-2"
            rows={5}
            placeholder="Please specify if any"
          />
        </div>
      )}
    </div>
  );
};

export default Step21;
