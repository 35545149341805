import React, { useEffect, useState } from "react";
import Logo from "../Components/common/Logo";
import PatientDetails from "../Components/PatientDetails";
import Profile from "../Components/Profile";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/rootReducer";
import withAuth from "../Components/common/withAuth"; 
import { logoutSuccess } from "../../Redux/reducers/authSlice";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  const [totalSubmission, setTotalSubmission] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch=useDispatch();
  const navigate= useNavigate();

  useEffect(() => {
    getTotalCount();
  }, []);

  const getTotalCount = async () => {
    setLoading(true);
    try {
      const response = await authAxios().get(`/leads/executive/${user?.id}`);
      const resData = response?.data;
      setTotalSubmission(resData?.count);
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response?.data?.message) {
        errMsg = error.response?.data?.message;
      } else {
        errMsg = "Failed to fetch excutive list data";
      }
      if (errMsg === "Unauthorized") {
        dispatch(logoutSuccess()); 
        navigate("/login");
      } else {
        toast.error(errMsg);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#EAEBED] px-[15px] py-[50px] min-h-[100vh] flex items-center justify-center">
      <div className="flex flex-col flex-wrap gap-[20px] lg:flex-row w-[100%] lg:w-[95%]">
        <div className="w-[100%] lg:w-[100%] text-center pb-[20px]">
          <Logo />
        </div>
        <div className="w-[100%] lg:w-[calc(70%-10px)]">
          <Profile />
        </div>
        <div className="w-[100%] lg:w-[calc(30%-10px)]">
          <div className="h-[100%]">
            <div className="bg-[#fff] px-[20px] py-[20px] rounded-[20px] h-[100%] flex flex-col justify-between lg:px-[50px] lg:py-[50px] lg:min-h-[300px] min-h-[200px]">
              <div className="subHead text-left">
                <h4 className="text-[25px] font-medium leading-[1.1]">
                  Total Submission
                </h4>
              </div>
              <div className="text-right">
                <span className="counter top-[0px] font-medium text-[clamp(40px,7vw,140px)] leading-[1.1] relative lg:top-[20px]">
                  {totalSubmission}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[100%]">
          <PatientDetails />
        </div>
      </div>
    </div>
  );
};

export default withAuth(Dashboard);
