import React, { useState, ChangeEvent, FormEvent } from 'react';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  file: File | null;
}

const ProfileForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    file: null,
  });

  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const file = files?.[0] || null;
      setFormData({ ...formData, [name]: file });

      // Preview the image
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    console.log(formData);
    // Perform form submission logic here
  };

  const handleEditMode = (): void => {
    setIsEditMode(!isEditMode);
  };

  return (
    <div className="bg-[#EAEBED] rounded-[10px] px-[33px] py-[38px]">
      <div>
        <h4 className="text-[25px] font-medium pb-[20px]">Add Executive</h4>
      </div>
      <div className="userForm">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap gap-[10px] justify-center executive-wrapper">
            <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px] flex items-center gap-[20px]">
              <label className="image-preview block relative w-[80px] h-[80px] cursor-pointer">
                {profileImage ? (
                  <img
                    src={profileImage as string}
                    alt="Profile"
                    className="w-[100%] h-[100%] rounded-[5px] object-cover"
                  />
                ) : (
                  <div className="placeholder w-[100%] h-[100%] flex bg-[#e0e0e0] items-center justify-center rounded-[5px]">
                    {/* Placeholder content */}
                  </div>
                )}
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  name="file"
                  onChange={handleChange}
                  disabled={!isEditMode}
                />
              </label>
              <span className="text-[#B0B0B0]">Replace Profile Image</span>
            </div>
            <div className="w-[100%] md:w-[calc(50%-5px)] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="w-[100%] md:w-[calc(50%-5px)] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="w-[100%] bg-[#fff] p-[20px] rounded-[5px]">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </div>
            
            <button
              type="button"
              className="bg-[#3B82F6] text-[#fff] px-[30px] py-[12px] rounded-[8px] text-[18px] font-normal mt-[1rem]"
              onClick={handleEditMode}
            >
              {isEditMode ? 'Add Executive' : 'Edit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
