import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PlansModal from "./common/Popup/PlansModal";
import { trimObjValues } from "../../Helper";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import { savePatientDetail } from "../../Redux/reducers/appSlice";
import { useAppDispatch } from "../../Redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/rootReducer";
import PaymentLinkModal from "./common/Popup/PaymentLinkModal";

interface FormData {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
}

// Validation schema without height, weight, and dob fields
const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string(),
  email: Yup.string().email("Invalid email address"),
});

const AddPatient: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { patientDetail } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.auth);

  console.log("user", user);

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [paymentLinkModal, setPaymentLinkModal] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [paymentLinkData, setPaymentLinkData] = useState<any>(null);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const handleSubmit = async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    const requestPayload = trimObjValues(values);
    setLoading(true);
    try {
      // const response = await authAxios().post("/patient", requestPayload);
      // console.log("patient-create-response", response);
      // const resData = response?.data;
      const user = requestPayload;
      if (user) {
        dispatch(savePatientDetail(user));
        setIsModalOpen(true);
      }
    } catch (error: any) {
      let errMsg = "Failed to create patient";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const generatePaymentLink = async (planId: any) => {
    setLoading(true);
    const payload: any = {
      patientId: Number(patientDetail.id),
      planPriceId: planId,
      phoneNumber: patientDetail?.phoneNumber,
      firstName: patientDetail.firstName,
      lastName: patientDetail.lastName,
    };
  
    if (patientDetail?.email) {
      payload.email = patientDetail.email;
    }
    try {
      const response = await authAxios().post(
        "/payment/generate-payment-link",
        payload
      );
      const resData = response.data;
      const toastMsg = "Payment link sent successfully";
      if (resData.url && resData.id) {
        setPaymentLinkData(resData);
        console.log(resData,paymentLinkData,"resDataresDataresData")
        // createLeadApi(planId, resData.id);
        setIsModalOpen(false);
        setPaymentLinkModal(true);
        setPaymentLink(resData.url);
        toast.success(toastMsg);
      }
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to generate payment link";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const createLeadApi = async (plan_Id: string, payment_Id: string) => {
    setLoading(true);
    const payload : any = {
      paymentId: payment_Id,
      planPriceId: plan_Id,
      email: user?.email,
      firstName: user.firstName,
      lastName: user.lastName,
      executiveId: user?.id,
      paymentData: paymentLinkData,
  };
  
    if (user?.phoneNumber) {
        payload.phoneNumber = user.phoneNumber;
    }
    
    try {
      const response = await authAxios().post("/leads/create", payload);
      const resData = response.data;
      const toastMsg = resData.message || "lead created successfully";
      toast.success(toastMsg);
    } catch (error: any) {
      let errMsg = "";
      if (Array.isArray(error.response?.data?.message)) {
        errMsg = error.response?.data?.message[0];
      } else if (error.response.data.message) {
        errMsg = error.response.data.message;
      } else {
        errMsg = "Failed to generate payment link";
      }
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentLinkData && selectedPlanId) {
      createLeadApi(selectedPlanId, paymentLinkData.id);
    }
  }, [paymentLinkData]); 

  const handleConfirm = (planId: any) => {
    setSelectedPlanId(planId);
    generatePaymentLink(planId);
    // navigate("/add-patient-details");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className="bg-gray-100 h-screen overflow-auto">
      <div className="w-[95%] tex max-w-[500px] mx-auto py-6 px-4 border bg-white rounded-lg shadow-lg mt-[100px] mb-[100px]">
        <div onClick={navigateToHome} className="flex justify-center cursor-pointer">
          <img
            src="./assets/images/SiteLogo.png"
            alt="Logo"
            className="max-w-[180px] text-center mb-[20px]"
          />
        </div>
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form>
            <div className="flex flex-col gap-y-2 mb-2">
              <label htmlFor="firstName">
                First Name <span className="text-red-500">*</span>
              </label>
              <Field
                className="bg-white rounded-[5px] py-2 px-4 border"
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-[13px]"
              />
            </div>
            <div className="flex flex-col gap-y-2 mb-2">
              <label htmlFor="lastName">
                Last Name <span className="text-red-500">*</span>
              </label>
              <Field
                className="bg-white rounded-[5px] py-2 px-4 border"
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-500 text-[13px]"
              />
            </div>
            <div className="flex flex-col gap-y-2 mb-2">
              <label htmlFor="phoneNumber">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <Field
                className="bg-white rounded-[5px] py-2 px-4 border"
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone number"
              />
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-500 text-[13px]"
              />
            </div>
            <div className="flex flex-col gap-y-2 mb-2">
              <label htmlFor="email">Email</label>
              <Field
                className="bg-white rounded-[5px] py-2 px-4 border"
                type="email"
                id="email"
                name="email"
                placeholder="Email (optional)"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-[13px] mt-1"
              />
            </div>
            <div className="py-5 text-center">
              <button
                type="submit"
                className="bg-black text-white max-w-[150px] rounded-[5px] w-full min-h-[40px] px-2 py-2"
                disabled={isSubmitting || loading}
              >
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
            {isModalOpen && (
              <PlansModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            )}
            {paymentLinkModal && (
              <PaymentLinkModal
                isOpen={paymentLinkModal}
                paymentUrl={paymentLink}
                onRequestClose={() => {
                  setPaymentLinkModal(false);
                  resetForm();
                  navigate("/add-patient");
                }}
              />
            )}
          </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddPatient;
