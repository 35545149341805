import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PatientDetails from "./../../executive/Components/PatientDetails";

interface AppState {
  patientDetail: any;
}

const initialState: AppState = {
  patientDetail: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    savePatientDetail: (state, action: PayloadAction<any>) => {
      state.patientDetail = action.payload;
    },
  },
});

export const { savePatientDetail } = appSlice.actions;

export default appSlice.reducer;
