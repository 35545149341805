// In leadsSlice.ts or similar
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LeadsState {
  totalExecutive: number;
  totalSubmission: number;
}

const initialState: LeadsState = {
  totalExecutive: 0,
  totalSubmission: 0,
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setTotalExecutive: (state, action: PayloadAction<number>) => {
      state.totalExecutive = action.payload;
    },
    incrementTotalExecutive: (state) => {
      state.totalExecutive += 1;
    },
    setTotalSubmission: (state, action: PayloadAction<number>) => {
      state.totalSubmission = action.payload;
    },
  },
});

export const { setTotalExecutive, incrementTotalExecutive, setTotalSubmission } = leadsSlice.actions;
export default leadsSlice.reducer;
