import React, { useState, useEffect } from "react";
import { StepProps, CheckboxItem } from "../../../config/types";

const Step15: React.FC<StepProps> = ({
  handleChange,
  formValues,
  setStepValid,
}) => {
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>([
    {
      id: 1,
      label: "Gallbladder disease",
      checked:
        formValues.q15_conditions?.includes("Gallbladder disease") || false,
    },
    {
      id: 2,
      label: "Alcohol/substance use disorder",
      checked:
        formValues.q15_conditions?.includes("Alcohol/substance use disorder") ||
        false,
    },
    {
      id: 3,
      label: "Hypertension (high blood pressure)",
      checked:
        formValues.q15_conditions?.includes(
          "Hypertension (high blood pressure)"
        ) || false,
    },
    {
      id: 4,
      label: "Seizures",
      checked: formValues.q15_conditions?.includes("Seizures") || false,
    },
    {
      id: 5,
      label: "Glaucoma",
      checked: formValues.q15_conditions?.includes("Glaucoma") || false,
    },
    {
      id: 6,
      label: "Sleep apnea",
      checked: formValues.q15_conditions?.includes("Sleep apnea") || false,
    },
    {
      id: 7,
      label: "Type 2 diabetes (not on insulin)",
      checked:
        formValues.q15_conditions?.includes(
          "Type 2 diabetes (not on insulin)"
        ) || false,
    },
    {
      id: 8,
      label: "Type 2 diabetes (on insulin) (exclude from GLP-1 consideration)",
      checked:
        formValues.q15_conditions?.includes(
          "Type 2 diabetes (on insulin) (exclude from GLP-1 consideration)"
        ) || false,
    },
    {
      id: 9,
      label: "Type 1 diabetes (exclude from GLP-1 consideration)",
      checked:
        formValues.q15_conditions?.includes(
          "Type 1 diabetes (exclude from GLP-1 consideration)"
        ) || false,
    },
    {
      id: 10,
      label:
        "Diabetic retinopathy (diabetic eye disease) (exclude from GLP-1 consideration)",
      checked:
        formValues.q15_conditions?.includes(
          "Diabetic retinopathy (diabetic eye disease) (exclude from GLP-1 consideration)"
        ) || false,
    },
    {
      id: 11,
      label:
        "History of or current pancreatitis (exclude from GLP-1 consideration)",
      checked:
        formValues.q15_conditions?.includes(
          "History of or current pancreatitis (exclude from GLP-1 consideration)"
        ) || false,
    },
    {
      id: 12,
      label:
        "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2",
      checked:
        formValues.q15_conditions?.includes(
          "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2"
        ) || false,
    },
    {
      id: 13,
      label: "Gout",
      checked: formValues.q15_conditions?.includes("Gout") || false,
    },
    {
      id: 14,
      label: "High cholesterol or triglycerides",
      checked:
        formValues.q15_conditions?.includes(
          "High cholesterol or triglycerides"
        ) || false,
    },
    {
      id: 15,
      label: "Depression",
      checked: formValues.q15_conditions?.includes("Depression") || false,
    },
    {
      id: 16,
      label: "Head injury",
      checked: formValues.q15_conditions?.includes("Head injury") || false,
    },
    {
      id: 17,
      label: "Tumor/infection in brain/spinal cord",
      checked:
        formValues.q15_conditions?.includes(
          "Tumor/infection in brain/spinal cord"
        ) || false,
    },
    {
      id: 18,
      label: "Low sodium",
      checked: formValues.q15_conditions?.includes("Low sodium") || false,
    },
    {
      id: 19,
      label: "Liver disease, including fatty liver",
      checked:
        formValues.q15_conditions?.includes(
          "Liver disease, including fatty liver"
        ) || false,
    },
    {
      id: 20,
      label: "Kidney disease",
      checked: formValues.q15_conditions?.includes("Kidney disease") || false,
    },
    {
      id: 21,
      label: "Elevated resting heart rate (tachycardia)",
      checked:
        formValues.q15_conditions?.includes(
          "Elevated resting heart rate (tachycardia)"
        ) || false,
    },
    {
      id: 22,
      label: "Coronary artery disease or heart attack/stroke in last 2 years",
      checked:
        formValues.q15_conditions?.includes(
          "Coronary artery disease or heart attack/stroke in last 2 years"
        ) || false,
    },
    {
      id: 23,
      label: "Congestive heart failure",
      checked:
        formValues.q15_conditions?.includes("Congestive heart failure") ||
        false,
    },
    {
      id: 24,
      label: "QT prolongation or other heart rhythm disorder",
      checked:
        formValues.q15_conditions?.includes(
          "QT prolongation or other heart rhythm disorder"
        ) || false,
    },
    {
      id: 25,
      label: "Hospitalization within the last 1 year",
      checked:
        formValues.q15_conditions?.includes(
          "Hospitalization within the last 1 year"
        ) || false,
    },
    {
      id: 26,
      label: "Human immunodeficiency virus (HIV)",
      checked:
        formValues.q15_conditions?.includes(
          "Human immunodeficiency virus (HIV)"
        ) || false,
    },
    {
      id: 27,
      label: "Acid reflux",
      checked: formValues.q15_conditions?.includes("Acid reflux") || false,
    },
    {
      id: 28,
      label: "Asthma/reactive airway disease",
      checked:
        formValues.q15_conditions?.includes("Asthma/reactive airway disease") ||
        false,
    },
    {
      id: 29,
      label: "Urinary stress incontinence",
      checked:
        formValues.q15_conditions?.includes("Urinary stress incontinence") ||
        false,
    },
    {
      id: 30,
      label: "Polycystic ovarian syndrome (PCOS)",
      checked:
        formValues.q15_conditions?.includes(
          "Polycystic ovarian syndrome (PCOS)"
        ) || false,
    },
    {
      id: 31,
      label: "Clinically proven low testosterone",
      checked:
        formValues.q15_conditions?.includes(
          "Clinically proven low testosterone"
        ) || false,
    },
    {
      id: 32,
      label: "Osteoarthritis",
      checked: formValues.q15_conditions?.includes("Osteoarthritis") || false,
    },
    {
      id: 33,
      label: "None of the above",
      checked:
        formValues.q15_conditions?.includes("None of the above") || false,
    },
  ]);

  useEffect(() => {
    const isStepValid = checkboxes.some((checkbox) => checkbox.checked);
    setStepValid(isStepValid);

    const selectedOptions = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.label);

    handleChange({
      target: {
        name: "q15_conditions",
        value: selectedOptions,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }, [checkboxes, setStepValid]);

  const handleCheckboxChange = (id: number) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );

    // Uncheck 'None of the above' if any other option is selected
    if (id !== 33 && updatedCheckboxes[id - 1].checked) {
      updatedCheckboxes[32].checked = false;
    }

    // Uncheck all other options if 'None of the above' is selected
    if (id === 33 && updatedCheckboxes[32].checked) {
      updatedCheckboxes.forEach((checkbox, index) => {
        if (index !== 32) checkbox.checked = false;
      });
    }

    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div className="min-h-[50vh] lg:px-[50px] px-[0px] ">
      <p className="text-[#000000] text-[18px] font-[400] text-left mb-5">
        15. Do any of these apply to you?
      </p>
      <div className="my-3">
        {checkboxes.map((checkbox) => (
          <div key={checkbox.id} className="mb-3">
            <label className="flex items-center relative block">
              <img
                src={
                  checkbox.checked ? "/assets/images/icons/check.svg" : "/assets/images/icons/uncheck.svg"
                }
                width={37}
                height={37}
                alt="Checkbox Image"
              />
              <input
                type="checkbox"
                name={`checkbox-${checkbox.id}`}
                checked={checkbox.checked}
                onChange={() => handleCheckboxChange(checkbox.id)}
                className="absolute w-[37px] h-[37px] opacity-0"
              />
              <span className="ml-2 text-[#484848] text-[16px]">
                {checkbox.label}
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Step15;
