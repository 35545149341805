import React, { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../Redux/rootReducer";

interface ModalProps {
  isOpen: boolean;
  paymentUrl: string;
  onRequestClose: () => void;
}

const PaymentLinkModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  paymentUrl,
}) => {
  const navigate = useNavigate();
  const { patientDetail } = useSelector((state: RootState) => state.app);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "90%",
      width: "500px",
      padding: "30px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  useEffect(() => {
    console.log("PaymentModal");
    console.log("isOpen", isOpen);
    console.log("paymentUrl", paymentUrl);
  }, [isOpen, paymentUrl]);

  const handleClose = () => {
    onRequestClose();
    navigate("/add-patient"); 
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose} // Use the new handler
      contentLabel="Payment Link Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <h2 className="text-xl text-center font-semibold mb-4">
        Payment Link Sent Successfully!
      </h2>
      <p className="mb-4 mt-2 text-center">
        The payment link has been successfully sent to {patientDetail.firstName}{" "}
        {patientDetail.lastName}.
      </p>
      <div className="flex mt-[30px] mb-3 justify-center">
        <button
          onClick={handleClose}
          type="button"
          className="bg-blue-400 text-white px-4 py-2 text-center rounded w-full"
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default PaymentLinkModal;
